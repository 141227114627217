import React, { useEffect, useRef, useState } from "react";
import CanvasJS from "../canvasjs/canvasjs.min.js";
import { getDateFromHexDay } from "../HelperFunctions.js";
import { nFormatter } from "../HelperFunctions.js";

function StakingLadderChart({ miners, quotes, selectedCurrency }) {
    //const [data, setData] = useState([]);
  
    const chartRef = useRef(null);
  
    useEffect(() => {
      if(!miners || !Array.isArray(miners) || miners.length < 1|| !miners[0].results || !miners[0].results.progressionData){
        return;
      }

      const groupedData = groupDataByEndDay(miners);

      // START DATA POINT
      const mappedDataPoints = groupedData.map((singleData) => {
        let yData = singleData.y ? singleData.y : 0;

        // if(quotes && quotes.length > 0 && selectedCurrency !== null) {
        //   debugger;
        //   let hexId = `HEX_${selectedCurrency.id}`;
        //   quotes.forEach((q) =>{
        //       if(q.id === "HEX" && q[hexId]){
        //           yData  *= q[hexId];
        //       }
        //   })
        // }

        let valueLabel = parseFloat(yData).toFixed(2);
        valueLabel = nFormatter(valueLabel,2);

        if(selectedCurrency !== null){
            if(selectedCurrency.id === "USDC") {
                valueLabel = "$"+valueLabel;
            }
            else {
                valueLabel += " " + selectedCurrency.symbol
            }
        }
        else {
            valueLabel += " HEX"
        }

        return({
            x: singleData.x,
            y: yData,
            label: `${singleData.x.toLocaleDateString()}<br/>Total Mined: ${valueLabel}<br/>Total T-Shares: ${singleData.rest.tshares.toFixed(2)}<br/>Y-Value`,
            color: singleData.color
          })
      });
      // END DATA POINT


      const minEndDay = Math.min(...mappedDataPoints.map(({x}) => x)) - (1209600000 * 2);
      const maxEndDay = Math.max(...mappedDataPoints.map(({x}) => x)) + (1209600000 * 2);

      let symbol = "(HEX)"
      if(selectedCurrency !== null){
        if(selectedCurrency.id === "USDC") {
            symbol = "($)"
        }
        else {
            symbol = "(" + selectedCurrency.symbol + ")"
        }
      }


      const chart = new CanvasJS.Chart(chartRef.current, {
        backgroundColor: "transparent",
        zoomEnabled: true,
        animationEnabled: true,
        toolTip: {
            fontColor: "#333333" // change to the color you want
        },
        title: {
          text: "Mining Schedule",
          fontColor: "white",
          fontFamily: "Arial"
        },
        axisX: {
          //title: "Date",
          valueFormatString: "MMM DD, YYYY",
          labelFontColor: "#E6E1E1",
          titleFontColor: "white",
          titleFontFamily: "Helvetica",
          minimum: minEndDay,
          maximum: maxEndDay,
          labelFormatter: function(e) {
            return CanvasJS.formatDate(e.value, "YYYY-MM");
          },
          labelAngle: -45,
          
        },
        axisY: {
          title: "Daily Mined " + symbol,
          labelFontColor: "#E6E1E1",
          titleFontColor: "white",
          titleFontFamily: "Helvetica",
          logarithmic: true,
          minimum: null,
          maximum: null,
        },
        data: [
          {
            type: "column",
            dataPointWidth: 20,
            
            //markerSize: 2, // set the marker size
            //markerColor: "blue", // set the marker color
            dataPoints: mappedDataPoints,
          },
        ],
      });
  
      chart.render();
    }, [miners, selectedCurrency]);
  

    function getColorForPoint(x, minDate, maxDate) {
        
          let startGradient = 0.3;
          let middleGradient1 = 0.6;
          let middleGradient2 = 0.9;
          let endGradient = 1;
        
          const percentage = (x - minDate) / (maxDate - minDate);
          const gradient1 = startGradient;
          const gradient2 = middleGradient1;
          const gradient3 = middleGradient2;
          const gradient4 = endGradient;
          
          let color1 = [255, 210, 21];
          let color2 = [217, 24, 166];
          let color3 = [217, 24, 166];
          let color4 = [219, 23, 255];
        
          let red, green, blue;
        
          if (percentage < gradient1) {
            red = color1[0];
            green = color1[1];
            blue = color1[2];
          } else if (percentage < gradient2) {
            const adjustedPercentage = (percentage - gradient1) / (gradient2 - gradient1);
            red = Math.round(color1[0] + (color2[0] - color1[0]) * adjustedPercentage);
            green = Math.round(color1[1] + (color2[1] - color1[1]) * adjustedPercentage);
            blue = Math.round(color1[2] + (color2[2] - color1[2]) * adjustedPercentage);
          } else if (percentage < gradient3) {
            const position = (percentage - gradient2) / (gradient3 - gradient2);
            red = Math.round(color2[0] * (1 - position) + color3[0] * position);
            green = Math.round(color2[1] * (1 - position) + color3[1] * position);
            blue = Math.round(color2[2] * (1 - position) + color3[2] * position);
          } else if (percentage < gradient4) {
            const position = (percentage - gradient3) / (gradient4 - gradient3);
            red = Math.round(color3[0] * (1 - position) + color4[0] * position);
            green = Math.round(color3[1] * (1 - position) + color4[1] * position);
            blue = Math.round(color3[2] * (1 - position) + color4[2] * position);
          } else {
            red = color4[0];
            green = color4[1];
            blue = color4[2];
          }
        
        return `rgb(${red}, ${green}, ${blue})`;
      }
  
      function groupDataByEndDay(dataset) {
        const groupedData = {};
      
        if (!dataset) {
          return [];
        }

        dataset.forEach(i => {
          let results = i.results;
          const endDay = results.endDay;
    
          if (!groupedData[endDay]) {
            groupedData[endDay] = { endDay, combinedValue: 0, tshares: 0 };
          }


          let transformedCombinedValue = results.hexCombinedValue;

          if(quotes && quotes.length > 0 && selectedCurrency !== null) {
            let _hex = i.chainId === "1" ? "HEX" : "pHEX";
            let hexId = `${_hex}_${selectedCurrency.id}`;
            quotes.forEach((q) =>{
                if(q.id === _hex && q[hexId]){
                  transformedCombinedValue  *= q[hexId];
                }
            })
          }

          groupedData[endDay].combinedValue += transformedCombinedValue;
          groupedData[endDay].tshares += results.tshares;

        });
      
        const minEndDay = Math.min(...Object.keys(groupedData)) - 30;
        const maxEndDay = Math.max(...Object.keys(groupedData)) + 30;
      
        // Create chart data points
        const result = Object.values(groupedData).map(({ endDay, ...rest }) => {

          return ({
            x: getDateFromHexDay(endDay),
            y: rest.combinedValue,
            label: "",
            rest: rest,
            color: getColorForPoint(endDay, minEndDay, maxEndDay),
          });
        })
        
      
        return result;
      }

    
  
    return <div ref={chartRef} className="chart-background" style={{ height: "300px", width: "100%" }} />;
  }

  export default StakingLadderChart;