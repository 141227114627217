import React from "react";
import { getAddressShortName, convertHeartsToHex } from "../../Functional/Web3/ContractInteraction";
import { shortenChainName, UTCtoLocalDateString, millisecondsToDays, getCurrentUTCTimestamp, daysToMilliseconds, nFormatter } from "../../Functional/HelperFunctions";

export default function MiningListItem ({item, section, index, quotes, selectedCurrency}) {

    if( !item || !item.results ) return ""

    let millisecondsToServe = daysToMilliseconds(item.stakedDays);
    let endDayInSeconds = ((item.stakeStartTimestamp * 1000) + millisecondsToServe) / 1000;

    let currentUTCtimestamp = getCurrentUTCTimestamp();
    let millisecondsRemaining = (item.stakeStartTimestamp * 1000) + millisecondsToServe - currentUTCtimestamp;

    let hexPrincipleValueRaw = convertHeartsToHex(parseFloat(item.stakeHearts));
    let hexMinedValueRaw = item.results.hexMined//principleValue + item.results.hexMined;
    let hexTotalValueRaw = item.results.hexCombinedValue//principleValue + hexMinedValueRaw;
    let hexPrincipleValue = hexPrincipleValueRaw;
    let hexMinedValue = hexMinedValueRaw;
    let hexTotalValue = hexTotalValueRaw;

    let hdrnMinted = item.results.hdrnMinted;
    let hdrnMintable = item.results.hdrnMintable;
    let hdrnFutureMint = item.results.hdrnFutureMintable;
    
    if(item.stakeEndTimestamp === 0){
        // do somthing here   
    } 
    else if (item.stakeEndTimestamp !== 0) {
        millisecondsRemaining = 0;
        // account for payout and penalty once fixed
    }

    
    let hdrnCurrentMintValueInHex = 0;
    if(quotes && quotes.length > 0){
        quotes.forEach(q=>{
            let hdrnId = item.chainId === "1" ? "HDRN" : "pHDRN";
            let hexId = item.chainId === "1" ? "HEX" : "pHEX";
            let qId = `${hdrnId}_${hexId}`
            if(q.id === hdrnId && q[qId]) {
                hdrnCurrentMintValueInHex = (hdrnMintable + hdrnMinted) * q[qId];
            }
        })
    }

    if(quotes && quotes.length > 0 && selectedCurrency !== null) {
        let _hdrnId = item.chainId === "1" ? "HDRN" : "pHDRN";
        let _hexId = item.chainId === "1" ? "HEX" : "pHEX";

        let hdrnId = `${_hdrnId}_${selectedCurrency.id}`;
        let hexId = `${_hexId}_${selectedCurrency.id}`;
        quotes.forEach((q) =>{
            if(q.id === _hdrnId && q[hdrnId]){
                //Add HDRN Code here
                hdrnMintable *= q[hdrnId];
                hdrnFutureMint *= q[hdrnId];
            }

            if(q.id === _hexId && q[hexId]){
                hexPrincipleValue  *= q[hexId];
                hexMinedValue *= q[hexId];
                hexTotalValue *= q[hexId];
            }
        })
    }

    let adjustedAPY = ((hexMinedValueRaw + hdrnCurrentMintValueInHex) / hexPrincipleValueRaw) * (365 / parseFloat(item.results.daysServed <= 0 ? 1 : item.results.daysServed))
    adjustedAPY = (adjustedAPY * 100).toFixed(1);


    hexPrincipleValue = nFormatter(hexPrincipleValue,2);
    hexMinedValue = nFormatter(hexMinedValue,2);
    hexTotalValue = nFormatter(hexTotalValue,2);

    hdrnMintable = nFormatter(hdrnMintable,2);
    hdrnFutureMint = nFormatter(hdrnFutureMint,2);

    if(selectedCurrency !== null) {
        if(selectedCurrency.id === "USDC") {
            hexPrincipleValue = "$" + hexPrincipleValue;
            hexMinedValue = "$" + hexMinedValue;
            hexTotalValue = "$" + hexTotalValue;

            hdrnMintable = "$" + hdrnMintable;
            hdrnFutureMint = "$" + hdrnFutureMint;
        }
        else {
            //hexMinedValue += " " + selectedCurrency.symbol;
        }
    }

    let pctComplete = item.results.daysServed / (item.stakedDays <= 0 ? 1 : item.stakedDays);
    pctComplete = (pctComplete < 0 ? 0 : pctComplete * 100).toFixed(1);

    let showOnSection = (_section) => {
        if(typeof _section == "number"){            
            let num = _section;
            _section = []
            _section.push(num);
        }        
        
        if(!_section.includes(section))
            return "d-none"
        return ""
    }

    // Return this data for each miner
    return(
            <tr key={index}>
                <td className={showOnSection([0,1])}>{shortenChainName(item.chainName)}</td>
                <td className={showOnSection([0,1])}>{item.name ? item.name : getAddressShortName(item.owningAddress ? item.owningAddress : item.stakerAddress)}</td>
                <td className={showOnSection(0)}>{item.stakeType === "Standard" ? "Native" : item.stakeType}</td>
                <td className={showOnSection(0)}>{UTCtoLocalDateString(item.stakeStartTimestamp)}</td>
                <td className={showOnSection(0)}>{UTCtoLocalDateString(endDayInSeconds)}</td>
                <td className={showOnSection(0)}>{nFormatter(item.stakeShares, 2)}</td>
                <td className={showOnSection(0)}>{hexTotalValue}</td>

                <td className={showOnSection(1)}>{hexPrincipleValue}</td>
                <td className={showOnSection(1)}>{hexMinedValue}</td>
                <td className={showOnSection(1)}>{hdrnMintable}</td>
                <td className={showOnSection(1)}>{hdrnFutureMint}</td>
                <td className={showOnSection(1)}>{adjustedAPY + "%"}</td>

                <td style={{display: "none"}}>.{(item.owningAddress ? item.owningAddress : item.stakerAddress).toString()}</td>
                <td style={{display: "none"}}>{(item.owningAddress ? "." + item.stakerAddress : "").toString()}</td>
                <td style={{display: "none"}}>{pctComplete}</td>
                <td style={{display: "none"}}>{hexMinedValueRaw.toFixed(0)}</td>
                <td style={{display: "none"}}>{hexMinedValue}</td>
                <td style={{display: "none"}}>{item.results.hdrnFutureMintable}</td>
                <td style={{display: "none"}}>{item.results.hdrnLifetimeMintable}</td>
                <td style={{display: "none"}}>{item.results.hdrnMintable}</td>
                <td style={{display: "none"}}>{item.results.hdrnMinted}</td>
            </tr>
    )
}