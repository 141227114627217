import React, { useEffect, useRef, useState } from "react";
import CanvasJS from "../canvasjs/canvasjs.min.js";
import { getDateFromHexDay } from "../HelperFunctions.js";
import { nFormatter } from "../HelperFunctions.js";
import { numberWithCommas } from "../HelperFunctions.js";



function DailyYieldChart({ miners, quotes, selectedCurrency }) {
    //const [data, setData] = useState([]);
  
    const chartRef = useRef(null);
  
    useEffect(() => {
      if(!miners || !Array.isArray(miners) || miners.length < 1|| !miners[0].results || !miners[0].results.progressionData){
        return;
      }

      const groupedData = groupDataByHexDay(miners);
      /// Start datapoint
      let cumulativeYield = 0;
      let cumulativeYieldPlsChain = 0;
      let labelAdded = 0;

      // Begin Mapper Function
      let mapperFunction = (singleData, index, chain) => {
        let yData = chain === "PulseChain Mainnet" ? singleData.yPls : singleData.y;
        // let yData = chain === "PulseChain Mainnet" ? singleData.y : singleData.y;
        if(!yData) {
          yData = 0;
        }
        let hexName = chain === "PulseChain Mainnet" ? "pHEX" : "HEX";
        if(quotes && quotes.length > 0 && selectedCurrency !== null) {
            
            let hexId = `${hexName}_${selectedCurrency.id}`;
            quotes.forEach((q) =>{
                if(q.id === hexName && q[hexId]){
                    yData  *= q[hexId];
                }
            })
        }

        //cumulativeYield += parseFloat(yData);
        cumulativeYield += chain === "PulseChain Mainnet" ? 0 : parseFloat(yData);
        cumulativeYieldPlsChain += chain === "PulseChain Mainnet" ? parseFloat(yData) : 0;

        let cumulativeYieldLabel = 0;
        let cumulativeYieldLabelShort = 0;
        let valueToUse = chain === "PulseChain Mainnet" ? cumulativeYieldPlsChain : cumulativeYield
        
        try {
          
          cumulativeYieldLabel = numberWithCommas(parseFloat(valueToUse).toFixed(2));
          cumulativeYieldLabelShort = nFormatter(parseFloat(valueToUse).toFixed(2),2);
        }
        catch {
          cumulativeYieldLabel= valueToUse;
          cumulativeYieldLabelShort = cumulativeYieldLabel
        }

        let valueLabel = parseFloat(yData).toFixed(2);
        valueLabel = nFormatter(valueLabel,2);

        if(selectedCurrency !== null){
            if(selectedCurrency.id === "USDC") {
                valueLabel = "$"+valueLabel;
                cumulativeYieldLabel = "$"+cumulativeYieldLabel;
                cumulativeYieldLabelShort = "$"+cumulativeYieldLabelShort;
            }
            else {
                valueLabel += " " + selectedCurrency.symbol
                cumulativeYieldLabel += " " + selectedCurrency.symbol
                cumulativeYieldLabelShort += " " + selectedCurrency.symbol
            }
        }
        else {
            valueLabel += " " + hexName;
            cumulativeYieldLabel += " " + hexName;
            cumulativeYieldLabelShort += " " + hexName;
        }



        let fontSize = 8;
        let numberOfLabels = 5;
        if (index % parseInt(groupedData.length / numberOfLabels) === 0) {
          labelAdded += 1;
          fontSize = 8 + parseFloat(labelAdded / 2);
          if(fontSize > 12){
            fontSize = 12;
          }
        }
        
        return({
            x: singleData.x,
            y: yData,
            tooltip: `${singleData.x.toLocaleDateString()}<br/>
                  Daily Mined: ${valueLabel}<br/>
                  Total Mined: ${cumulativeYieldLabel}<br/>
                  Total T-Shares: ${parseFloat(singleData.rest.tshares).toFixed(2)}<br/>
                  Payout Per T-Share: ${singleData.rest.dailyPayout.toFixed(2)}<br/>
                  Y-Value`,
            color: chain === "PulseChain Mainnet" ? singleData.color.pls : singleData.color.eth,
            indexLabel: index % parseInt(groupedData.length / numberOfLabels) === 0 ? cumulativeYieldLabelShort : '',
            indexLabelFontColor: "white",//singleData.color,
            indexLabelPlacement: 'top-left',
            indexLabelFontSize: fontSize
          })
      }
      // End Mapper Function



      let mappedDataPoints = groupedData.filter(g=>g.y > 0).map(function(singleData, index) {
        return mapperFunction(singleData, index, "Ethereum Mainnet");
      });
      let mappedPulseChainPoints = groupedData.filter(g=>g.yPls > 0).map(function(singleData, index) {
      // let mappedPulseChainPoints = groupedData.filter(g=>g.y > 0).map(function(singleData, index) {
        return mapperFunction(singleData, index, "PulseChain Mainnet");
      });

      for(let i = 0; i < mappedPulseChainPoints.length; i++) {
        mappedDataPoints.push(mappedPulseChainPoints[i]);
      }




      let symbol = "(HEX)"
      if(selectedCurrency !== null){
        if(selectedCurrency.id === "USDC") {
            symbol = "($)"
        }
        else {
            symbol = "(" + selectedCurrency.symbol + ")"
        }
      }



      const chart = new CanvasJS.Chart(chartRef.current, {
        backgroundColor: "transparent",
        zoomEnabled: true,
        animationEnabled: true,
        toolTip: {
            fontColor: "#333333", // change to the color you want
            content: '{tooltip}'
        },
        title: {
          text: "Daily Mining Progress",
          fontColor: "white",
          fontFamily: "Arial"
        },
        axisX: {
          //title: "Date",
          valueFormatString: "MMM DD, YYYY",
          labelFontColor: "#E6E1E1",
          titleFontColor: "white",
          titleFontFamily: "Helvetica"
        },
        axisY: {
          title: "Daily Mined " + symbol,
          labelFontColor: "#E6E1E1",
          titleFontColor: "white",
          titleFontFamily: "Helvetica"
        },
        data: [
          {
            type: "stackedColumn", //column
            markerSize: 2, // set the marker size
            //markerColor: "blue", // set the marker color
            dataPoints: mappedDataPoints,
          },
        ],
        
      });
  
      chart.render();
    }, [miners, selectedCurrency]);
  
    /*
    function getColorForPoint(x, minDate, maxDate) {
        const percentage = (x - minDate) / (maxDate - minDate);
        const red = Math.round(255 * percentage);
        const green = Math.round(255 * (1 - percentage));
        return `rgb(${red}, 255, ${green})`;
    }
    */

    function getColorForPoint(x, minDate, maxDate) {

          let startGradient = 0.3;
          let middleGradient1 = 0.6;
          let middleGradient2 = 0.9;
          let endGradient = 1;
        
          let percentage = (x - minDate) / (maxDate - minDate);
          let gradient1 = startGradient;
          let gradient2 = middleGradient1;
          let gradient3 = middleGradient2;
          let gradient4 = endGradient;
          
          let color1 = [255, 210, 21];
          let color2 = [217, 24, 166];
          let color3 = [217, 24, 166];
          let color4 = [219, 23, 255];
        
          let red, green, blue;

          let colorsObj = {}
        
          if (percentage < gradient1) {
            red = color1[0];
            green = color1[1];
            blue = color1[2];
          } else if (percentage < gradient2) {
            const adjustedPercentage = (percentage - gradient1) / (gradient2 - gradient1);
            red = Math.round(color1[0] + (color2[0] - color1[0]) * adjustedPercentage);
            green = Math.round(color1[1] + (color2[1] - color1[1]) * adjustedPercentage);
            blue = Math.round(color1[2] + (color2[2] - color1[2]) * adjustedPercentage);
          } else if (percentage < gradient3) {
            const position = (percentage - gradient2) / (gradient3 - gradient2);
            red = Math.round(color2[0] * (1 - position) + color3[0] * position);
            green = Math.round(color2[1] * (1 - position) + color3[1] * position);
            blue = Math.round(color2[2] * (1 - position) + color3[2] * position);
          } else if (percentage < gradient4) {
            const position = (percentage - gradient3) / (gradient4 - gradient3);
            red = Math.round(color3[0] * (1 - position) + color4[0] * position);
            green = Math.round(color3[1] * (1 - position) + color4[1] * position);
            blue = Math.round(color3[2] * (1 - position) + color4[2] * position);
          } else {
            red = color4[0];
            green = color4[1];
            blue = color4[2];
          }
        colorsObj.eth = `rgb(${red}, ${green}, ${blue})`;
        // return `rgb(${red}, ${green}, ${blue})`;

          color1 = [255, 15, 15];
          color2 = [234, 7, 255];
          color3 = [2, 53, 232];
          color4 = [49, 249, 255];

          gradient1 = 0.1;
          gradient2 = 0.4;
          gradient3 = middleGradient2;
          gradient4 = endGradient;

          if (percentage < gradient1) {
            red = color1[0];
            green = color1[1];
            blue = color1[2];
          } else if (percentage < gradient2) {
            const adjustedPercentage = (percentage - gradient1) / (gradient2 - gradient1);
            red = Math.round(color1[0] + (color2[0] - color1[0]) * adjustedPercentage);
            green = Math.round(color1[1] + (color2[1] - color1[1]) * adjustedPercentage);
            blue = Math.round(color1[2] + (color2[2] - color1[2]) * adjustedPercentage);
          } else if (percentage < gradient3) {
            const position = (percentage - gradient2) / (gradient3 - gradient2);
            red = Math.round(color2[0] * (1 - position) + color3[0] * position);
            green = Math.round(color2[1] * (1 - position) + color3[1] * position);
            blue = Math.round(color2[2] * (1 - position) + color3[2] * position);
          } else if (percentage < gradient4) {
            const position = (percentage - gradient3) / (gradient4 - gradient3);
            red = Math.round(color3[0] * (1 - position) + color4[0] * position);
            green = Math.round(color3[1] * (1 - position) + color4[1] * position);
            blue = Math.round(color3[2] * (1 - position) + color4[2] * position);
          } else {
            red = color4[0];
            green = color4[1];
            blue = color4[2];
          }
          colorsObj.pls = `rgb(${red}, ${green}, ${blue})`;
          return colorsObj;
      }
  
    function groupDataByHexDay(dataset) {
        const groupedData = {};
      
        if(!dataset) {
            return []
        }
      
        let minHexDay = Infinity;
        let maxHexDay = -Infinity;

        dataset.forEach(({ results }) => {
          results.progressionData.forEach(({ hexDay, ...rest }) => {
            minHexDay = Math.min(minHexDay, hexDay);
            maxHexDay = Math.max(maxHexDay, hexDay);

            if (!groupedData[hexDay]) {
                groupedData[hexDay] = {
                  hexDay,
                  dailyYield: rest.chainName === "Ethereum Mainnet" ? rest.dailyYield : 0,
                  dailyYieldOnPls: rest.chainName === "PulseChain Mainnet" ? rest.dailyYield : 0,
                  tshares: rest.tshares,
                  dailyPayout: rest.dailyPayout
                };
              } else {
                groupedData[hexDay].dailyYield += rest.chainName === "Ethereum Mainnet" ? rest.dailyYield : 0;
                groupedData[hexDay].dailyYieldOnPls += rest.chainName === "PulseChain Mainnet" ? rest.dailyYield : 0;
                groupedData[hexDay].tshares += rest.tshares;
            }
          });
        });

        // Fill in any missing gaps
        for (let hexDay = minHexDay; hexDay <= maxHexDay; hexDay++) {
            if (!groupedData[hexDay]) {
              groupedData[hexDay] = { hexDay, dailyYield: 0, dailyYieldOnPls: 0, tshares: 0, dailyPayout: 0 };
            }

            groupedData[hexDay].color = getColorForPoint(hexDay, minHexDay, maxHexDay);
        }
      
        /* T SHARE ON Y
        const result = Object.values(groupedData).map(({ hexDay, ...rest }) => ({
            x: getDateFromHexDay(hexDay),
            y: rest.tshares,
            label: `HEX Day ${hexDay}: ${getDateFromHexDay(hexDay).toLocaleDateString()} - Daily Mined: ${rest.dailyYield.toFixed(2)}, Total T-Shares`,
        }));
        */

        const result = Object.values(groupedData).map(({ hexDay, ...rest }) => {

            return ({
                x: getDateFromHexDay(hexDay),
                y: rest.dailyYield,
                yPls: rest.dailyYieldOnPls,
                color: rest.color,
                rest: rest
            })
        }
        
        );
        return result;
    }

  
    return <div ref={chartRef} className="chart-background" style={{ height: "300px", width: "100%" }} />;
  }

  export default DailyYieldChart;