import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {isValidWallet} from "../../Functional/Web3/ContractInteraction";

function TextInputField({reference, minLength, maxLength, placeholder, handleOnSubmit, requireAddress, defaultValue, commaDelimited }){
    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(defaultValue ? defaultValue : "");
      }, [defaultValue]);

    const _placeholder = placeholder ? placeholder : "";

    const _handleOnSubmit = (event) =>{
        let result = "";
        if(minLength ? value.length < minLength : false) {
            return;
        }

        if(requireAddress && !commaDelimited) {
            if(!isValidWallet(value)) {
                // Do not execute the submit if required to be an address
                return;
            };
            result = value;
        }
        else if(requireAddress && commaDelimited) {
            let array = value.split(',')
            result = [];
            array.forEach(a=>{
                let trimmed = a.trim();
                if(!isValidWallet(trimmed)) {
                    // Do not execute the submit if required to be an address
                    return;
                } else {
                    result.push(trimmed);
                }
                
            })

        }
    
        if(handleOnSubmit && reference) {
            handleOnSubmit(result, reference)
            setValue("");
        }
        else if (handleOnSubmit) {
            handleOnSubmit(result)
            setValue("");
        }

    }

    const handleOnChange = (event) => {
        let val = event.target.value;

        if(maxLength ? val.length > maxLength : false) {
            return;
        }

        setValue(val);
    }

    return(
        <div className="input-container">
            <div className="d-inline padding-r10">
                <input 
                    className="input-control"
                    placeholder={_placeholder}
                    onChange={handleOnChange}
                    value={value}
                />
            </div>
            <div className="d-inline special-button" onClick={_handleOnSubmit}>
                <FontAwesomeIcon icon={faPlus}/>
            </div>
        </div>
    )
}

export default TextInputField;