import HexBanner from "./Art/Brands/hex_banner_small.png"

export const twitter = 'https://twitter.com/DevJuyoung';
export const donationAddress = '0xAAb38C19854d620154775f7246c1f7981273d54B'
export const supportedNetworks = [
    //{chainId: 0, platform: },
    {chainId: "1", platform: "Ethereum Mainnet"},
    {chainId: "942", platform: "** PulseChain V4 Testnet"},
]
//'0x761B933545b52A1c3CD9c1E6478D9859f38A38f4'

export const links = {
    titleDerivatives: { title: "HEX & HEX-Derivatives", isLink: false },
    hex: {url:"https://go.hex.com/", title:"HEX", isLink: true, img: HexBanner },
    icosa: {url:"https://app.icosa.pro", title: "Icosa X Hedron", isLink: true },
    auctions: {url:"https://hedron.today", title: "HSI Auction Dashboard", isLink: true },
    maximusdao: {url: "https://portfolio.maximus.cash/", title: "MaximusDAO Tracker", isLink: true },

    tradingAndSwaps: { title: "Trading & Swaps", isLink: false },
    pulsex: {url:"https://www.pulsex.com", title: "PulseX.com DEX", isLink: true },
    uniswap: {url:"https://www.uniswap.org", title: "Uniswap.org DEX", isLink: true },
    crosschain: {url:"https://portalxswap.io/", title: "PortalX Cross-Chain Swap", isLink: true },

    analyticAndMore: { title: "Analytics & More", isLink: false },
    pulsechain: {url:"https://www.pulsechain.com", title: "PulseChain.com", isLink: true },
    analytics: {url: "https://www.axisalive.com/hex-tools", title: "Analytics Tools & More", isLink: true }
};


export const maximusDaoTokens = {
    maxi: { platform: "Ethereum Mainnet", address: "0x0d86EB9f43C57f6FF3BC9E23D8F9d82503f0e84b", totalSupply: 27454606480744367 }    
    //,pMaxi: { platform: "PulseChain Mainnet", address: "0x0d86EB9f43C57f6FF3BC9E23D8F9d82503f0e84b", totalSupply: 27454606480744367 }    
    ,deci: { platform: "Ethereum Mainnet", address: "0x6b32022693210cD2Cfc466b9Ac0085DE8fC34eA6", totalSupply: 56599198772947111 }    
    //,pDeci: { platform: "PulseChain Mainnet", address: "0x6b32022693210cD2Cfc466b9Ac0085DE8fC34eA6", totalSupply: 56599198772947111 }    
    ,lucky: { platform: "Ethereum Mainnet", address: "0x6B0956258fF7bd7645aa35369B55B61b8e6d6140", totalSupply: 7498550167671512 }    
    //,pLucky: { platform: "PulseChain Mainnet", address: "0x6B0956258fF7bd7645aa35369B55B61b8e6d6140", totalSupply: 7498550167671512 }    
    ,trio: { platform: "Ethereum Mainnet", address: "0xF55cD1e399e1cc3D95303048897a680be3313308", totalSupply: 6961791147775001 }    
    //,pTrio: { platform: "PulseChain Mainnet", address: "0xF55cD1e399e1cc3D95303048897a680be3313308", totalSupply: 6961791147775001 }    
    ,base: { platform: "Ethereum Mainnet", address: "0xe9f84d418B008888A992Ff8c6D22389C2C3504e0", totalSupply: 6961791147775001 }    
    //,pBase: { platform: "PulseChain Mainnet", address: "0xe9f84d418B008888A992Ff8c6D22389C2C3504e0", totalSupply: 6961791147775001 }    
    ,poly: { platform: "Ethereum Mainnet", address: "0x9d93692E826A4bd9e903e2A27D7FbD1e116efdad", totalSupply: 1733385921719701502402 }    
    //,pPoly: { platform: "PulseChain Mainnet", address: "0x9d93692E826A4bd9e903e2A27D7FbD1e116efdad", totalSupply: 1733385921719701502402 }    
}