import React from "react";
import TwitterIcon from "../../Art/Brands/twitter.png";
import PatreonIcon from "../../Art/Brands/patreon_white.png";
import Logo from "../../Art/Brands/logo.png";
import Marquee from "Components/Functional/Marquee/Marquee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDollarToSlot, faInfoCircle, faHeart } from "@fortawesome/free-solid-svg-icons";
import { twitter, donationAddress } from "../../Globals"

class SiteHeader extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      donateMessageOpen: false
    }
  }
    goToTwitter=()=>{
      window.open(twitter, "_blank");
    }

    goToPatreon=()=>{
      window.open(twitter, "_blank");
    }

    copyToClipboard=()=>{
        let walletAddress = donationAddress
        // Create a temporary input element
        var tempInput = document.createElement("input");
      
        // Set the input element's value to the wallet address
        tempInput.value = walletAddress;
      
        // Append the input element to the body
        document.body.appendChild(tempInput);
      
        // Select the input element's contents
        tempInput.select();
      
        // Copy the selected contents to the clipboard
        document.execCommand("copy");
      
        // Remove the temporary input element from the body
        document.body.removeChild(tempInput);
      
        // Display a message to the user indicating that the wallet address has been copied to the clipboard
        alert("Donation address copied to clipboard: " + walletAddress);
    }

    render() {
      return (
        <div>
          <div className="site-header">
            {/* <div>
              <div className="site-header-text">
                
              </div>
            </div> */}


            <div className="site-header fixed-header">
              <div>
                <div className="site-header-logo">
                  <img src={Logo}/>
                </div>
                <div className="site-header-text">
                  HSIWatch
                </div>

                <div className="header-buttons">
                  <div className="patreon-image" onClick={this.goToPatreon}>
                    <img src={PatreonIcon}/><small>Patreon</small>
                  </div>
                  <div className="donate-image" onClick={this.copyToClipboard}>
                    <FontAwesomeIcon icon={faCircleDollarToSlot}/><small>Donate</small>
                  </div>
                  <div className="twitter-image" onClick={this.goToTwitter}>
                    <img src={TwitterIcon}/><small>Twitter</small>
                  </div>
                  <div className={`donate-message ${this.state.donateMessageOpen ? "open" : "close"}`} onClick={()=>this.setState({donateMessageOpen: !this.state.donateMessageOpen})}>
                    Enjoy HSI Watch?<span><FontAwesomeIcon icon={faHeart}/></span><br/>
                    Consider donating to support site maintanence and the development of more free tools like this one. 
                  </div>
                  <div className="donate-message-opener" onClick={()=>this.setState({donateMessageOpen: !this.state.donateMessageOpen})}>
                    <FontAwesomeIcon icon={faInfoCircle}/>
                  </div>

                  <div className="toggle-theme">
                    <select defaultValue={this.props.theme} onChange={(e)=>this.props.onChangeTheme(e.target.value)}>
                      <option value="0">Default</option>
                      <option value="4">PulseChain</option>
                      <option value="1">Hexican</option>
                      <option value="2">Classic</option>
                      <option value="3">Snow</option>
                    </select>
                  </div>
                </div>

                <div className="header-tickers">
                  {this.props.quotes && this.props.quotes.length > 0 && <Marquee quotes={this.props.quotes}/>}
                </div>
              </div>
            </div>
          </div>
        </div>
)
    }
  }
  
  export default SiteHeader;