import React from "react";
import UsdcIcon from "../../../Art/Tokens/USDC_ICON.png";

function CurrencySwitch ({provider, selectedItem, quotes, handleSelectItem, loading}) {

    const handleOnClick = (event, item) => {
        // When a currency is clicked perform an action
        if(selectedItem !== null) {
            if(selectedItem.id === item.id)
                handleSelectItem(null);
            else
                handleSelectItem(item);
        }
        else
            handleSelectItem(item);
    }
    
    const showIcons = (list) =>{
        if(list && list.length > 0) {
            return list.map((item, index)=>{
                return(
                    <div key={index} onClick={(e)=>handleOnClick(e, item)} className={classIsSelected(item)}>
                        <img src={item.logo_url}/>
                    </div>
                )
            })
        }
    }

    const classIsSelected=(item)=>{

        if(selectedItem === null || selectedItem === undefined)
            return "";

        if(selectedItem.id === item.id){
            return "selected-item"
        }
        else return "";
    }

    return(
        <div>
            <div className="left-nav-header">
                Currency View
            </div>
            
            <div className="t-muted padding-b20">
                <small style={{fontSize: '10px'}}>
                    Click an icon to convert dashboard
                </small>
                <div className="currency-change-icons">
                    <div onClick={(e)=>handleOnClick(e, {id: "USDC", symbol: "USDC"})} className={classIsSelected({id: "USDC"})}>
                        <img src={UsdcIcon}/>
                    </div>
                    {showIcons(quotes.filter(f=>f.platform==="ETH"))}<br/><br/>
                    {showIcons(quotes.filter(f=>f.platform==="PLS"))}
                </div>
            </div>
        </div>
    )
}

export default CurrencySwitch;