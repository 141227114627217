import React, {useState, useEffect } from "react";
import { nFormatter } from "../../Functional/HelperFunctions";
import { convertHeartsToHex } from "Components/Functional/Web3/ContractInteraction";
import LoadingIcon from "../../../Art/Animated/loading.gif";
//import PulsechainIcon from "../../../Art/Tokens/Pulsechain_small.png"
import { tokenImgs } from "../../Functional/HelperFunctions";
import Tooltip from "Components/Functional/Tooltip/Tooltip";
import { IcosaTooltip, ShareValueTooltip } from "Components/Functional/Tooltip/Tooltips/ToolTipInfo";

function LiquidBalances({ provider, balances, selectedCurrency, quotes, selectedWallet, loading }){
    const [displayBalance, setDisplayBalances] = useState([]);
    const [displayPulseBalance, setDisplayPulseBalances] = useState([]);
    const [init, setInit] = useState(false);

    useEffect(() => {
      if(!init) {
        setInit(true);
        return;
      }
    
      if(!quotes || balances === null || balances === undefined || quotes.length < 1) {
        return;
      }

      let platforms = [];
      balances.forEach(v => {
        const plat = v?.token?.platform;
        if (!platforms.includes(plat) && plat !== undefined) {
          platforms.push(plat);
        }
      });
  
      let combinedBalances = [];
      
      let showInCurrency = selectedCurrency ? selectedCurrency.id : "";

      let filteredBalances = balances.filter(e=> e && e.token !== undefined && e.token !== null && e.platform !== undefined)
      if(selectedWallet) {
        filteredBalances = filteredBalances.filter(e=>e.wallet.toUpperCase() === selectedWallet.address.toUpperCase())
      }

      for(let p = 0; p < platforms.length; p++) {
        let platformBalanceRaw = [];
        let platform = platforms[p];
        let platformFilteredBalances = filteredBalances.filter(pppp=>pppp.platform === platform);
        
        platformFilteredBalances.forEach(b=> {
          platformBalanceRaw.push(b);

          let exists = false;
          combinedBalances.forEach(cb=>{
            if(cb.token.address.toUpperCase() === b.token.address.toUpperCase() && cb.platform === platform){
              exists = true;
              cb.balance += parseFloat(b.balance);
            }
          })

          if(!exists) {
            let combinedBalanceObject = {
              platform: platform,
              token: b.token,
              balance: parseFloat(b.balance)
            }
            combinedBalances.push(combinedBalanceObject);
          }

        });
      }


    // GET QUOTES AND LOGO URL
    for(let i = 0; i < combinedBalances.length; i++) {
      let nomics_id = combinedBalances[i].token.nomics_id;
      let tokenName = combinedBalances[i].token.name;
      let platform = combinedBalances[i].platform;
      let balanceAmount = combinedBalances[i].balance;
      let quoteFound = false;

      let totalValue = 0;
      
      for (const quote of quotes) {
        if(!quote.platform || quote.platform != platform) {
          continue;
        }

        if (quote.id === nomics_id) {
          let convertedValue = 0;
          
          quoteFound = true;
          
          if(showInCurrency === "USDC"){
              convertedValue = parseFloat(balanceAmount) * parseFloat(quote.price);
          }
          else if(showInCurrency === "") {
              convertedValue = parseFloat(balanceAmount);                    
          }
          else if(showInCurrency === nomics_id){
              convertedValue = parseFloat(balanceAmount);
          }
          else if(showInCurrency !== "USDC"){
              let quoteRef = nomics_id + "_" + showInCurrency;
              convertedValue = parseFloat(balanceAmount) * parseFloat(quote[quoteRef]);
          }

          totalValue += convertedValue;
          combinedBalances[i].logo_url = quote.logo_url;
          break;
        }
      }
      if(!quoteFound) {
        totalValue += balanceAmount;

        let potential_logo = ""
        try {
          if(tokenImgs[tokenName]) {
            potential_logo = tokenImgs[tokenName]
          }
          else {
            for (const quote of quotes) {
              if(quote.name === tokenName) {
                potential_logo = quote.logo_url ? quote.logo_url : ""
              }
            }
          }
        }
        catch {
          potential_logo = ""
        }
        combinedBalances[i].logo_url = potential_logo;
      }

      combinedBalances[i].balance = parseFloat(totalValue);
      
    }
    let ethBals = combinedBalances.filter(cb=>cb.platform === "ETH");
    let plsBals = combinedBalances.filter(cb=>cb.platform === "PLS");
    setDisplayBalances(ethBals);
    setDisplayPulseBalances(plsBals);
        
    }, [balances, selectedCurrency, selectedWallet]);

    let showBalances = ()=> {
    return (
        <div className="liquid-chain">
          <div className="liquid-chain-title">Ethereum</div>
          <div className="row liquid-chain-balances">
            {displayBalance && displayBalance.map((item, index) => {
                    let showInCurrency = selectedCurrency ? selectedCurrency.id : "";
                    let prefix = showInCurrency === "USDC" ? "$" : "";
                    let suffix = showInCurrency !== "USDC" && showInCurrency !== "" ? selectedCurrency.name : "";
      
                    return (
                    <div key={index} className="col">
                        <Tooltip customClass="liquid-wallet-tooltip" text={item.token.name}>
                          <div className={`liquid-wallet-balance-token`}>
                              <img src={item.logo_url ? item.logo_url : ""} />
                              <span>{`${prefix}${nFormatter(item.balance, 2)} ${suffix}`}</span>
                          </div>
                        </Tooltip>
                    </div>
                  );
              })}
            </div>
          </div>
        )
    }

    let showPulseBalances = ()=> {
      return (
        <div className="liquid-chain">
          <div className="liquid-chain-title">PulseChain</div>
          <div className="row liquid-chain-balances">
            {displayPulseBalance && displayPulseBalance.map((item, index) => {
                    let showInCurrency = selectedCurrency ? selectedCurrency.id : "";
                    let prefix = showInCurrency === "USDC" ? "$" : "";
                    let suffix = showInCurrency !== "USDC" && showInCurrency !== "" ? selectedCurrency.name : "";
                    // let prefix = ""
                    // let suffix = showInCurrency !== "USDC" && showInCurrency !== "" ? item.token.name : "";
      
                    return (
                    <div key={index} className="col">
                        <Tooltip customClass="liquid-wallet-tooltip" text={item.token.name}>
                          <div className={`liquid-wallet-balance-token`}>
                              <img src={item.logo_url ? item.logo_url : ""} />
                              <span>{`${prefix}${nFormatter(item.balance, 2)} ${suffix}`}</span>
                          </div>
                        </Tooltip>
                    </div>
                  );
              })}
            </div>
          </div>
        )
    }
    
    return(
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row liquid-wallet-balance">
                        <div className="liquid-wallet-title">
                            Liquid Holdings
                        </div>
                        {!loading && showBalances()}
                        {!loading && showPulseBalances()}
                        {!provider && <div className="col-12 no-provider-liquid">Use a browser with a wallet extension to see liquid balances</div>}
                        {provider && loading && <div className="loading-liquid"><img src={LoadingIcon}/></div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LiquidBalances;