import { removeTokenFromStorage } from "Components/Functional/DataStorage/DataManager";
import TextInputField from "Components/Functional/Input/TextInputField";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesRectangle } from "@fortawesome/free-regular-svg-icons";

const TokenSettings = ({items, handleUpdateItems, quotes}) => {

    const handleOnRemove = (item) => {
        let newItems = [];
        items.forEach((i)=>{
            // if(i.nomics_id.toUpperCase() !== item.nomics_id.toUpperCase()){
            if(i.id !== item.id){
                newItems.push(i);
            }
        });
        handleUpdateItems(newItems);
        removeTokenFromStorage(item);
    }

    const showImage = (item) => {

        if(!quotes) return;
  
        let logo_url = "";
        let exists = false;
        for(let i = 0; i < quotes.length; i++){
          if(quotes[i]?.symbol === item.name){
            exists = true;
            logo_url = quotes[i].logo_url;
            break;
          }
        }
  
        if(!exists) return "";
        if(exists) return (
          <div className="ticker-image">
            <img src={logo_url}/>
          </div>
        )
      }


    const onlyShowNonHEX = (item) => {
        if(!(item.name === "HEX" || item.name === "ICSA" || item.name === "HDRN")) {
            return ""
        }
        else{
            return "d-none"
        }
    }

    return (
        <div className="drag-and-drop-container token-watchlist-settingmenu">
            <div className="drag-and-drop-area animate-items">
                {items.map((item, index)=> {

                    return (
                        <div key={index} className={`drag-and-drop-item`}>
                            <div className="token-setting-item">
                                {showImage(item)}
                                {item.name}
                                <div className={`remove-token-button ${onlyShowNonHEX(item)}`} onClick={()=>handleOnRemove(item)}>
                                    <FontAwesomeIcon icon={faTimesRectangle}/>
                                </div>
                            </div>
                        </div>

                    )
                }
                )}
            </div>
        </div>
    );
};

export default TokenSettings;