import { links } from "../../../Globals"

export default function QuickLinks () {
    return (
        <div className="quicklinks-list">
            <div className="left-nav-header">
                Quick Links
            </div>
            
            <div className="t-muted">
                {Object.keys(links).map((key, index)=>{

                    if(links[key].isLink) {
                        return (
                            <a key={index} href={links[key].url} target="_blank">
                                <div className="nav-link">
                                    {links[key].title}
                                </div>
                            </a>
                        )
                    }
                    else {
                        return (
                            <div key={index} className="nav-link-title">
                                {links[key].title}
                            </div>
                        )
                    }

                })}
            </div>
        </div>
    )
}