import { v4 as uuidv4 } from 'uuid';

let getSession = () => {
  const session = localStorage.session || '';
  if(session === '' || session === null || session === undefined) {
    const clientId = uuidv4();  
    localStorage.setItem('session', clientId);
    return clientId;
  }
  else {
    return session;
  }
  
}

let saveBalancesToStorage = (json) => {
  const currentDate = Date.now();

  let balancesObj = { balances: json, refreshTime: currentDate}

  localStorage.setItem('balances', JSON.stringify(balancesObj));
}

function getBalancesFromStorage() {
  const balances = localStorage.balances || '';
  if(balances === '' || balances === null || balances === undefined)
    return null;

  let result = {};
  try {
    result = JSON.parse(balances);
  }
  catch{
    return null;
  }

  return result;
}


export let saveMaximusDaoToStorage = (json) => {
  localStorage.setItem('maxiDao', JSON.stringify(json));
}

export function getMaximusDaoFromStorage() {
  const maxiDao = localStorage.maxiDao || '';
  if(maxiDao === '' || maxiDao === null || maxiDao === undefined)
    return null;

  let result = null;
  try {
    result = JSON.parse(maxiDao);
  }
  catch{
    return null;
  }

  return result;
}


let addWalletToStorage = (wallet) => {
  const walletList = localStorage.walletList || '';
  const wallets = walletList ? walletList.split(',') : [];
  if (wallets.includes(wallet)) {
    return;
  }
  wallets.push(wallet.name + ":" + wallet.address);
  localStorage.setItem('walletList', wallets.join(','));
}

let addMultipleWalletsToStorage = (multiwallets) => {
  const walletList = localStorage.walletList || '';
  const wallets = walletList ? walletList.split(',') : [];

  for(let i = 0; i < multiwallets.length; i++) {
    let wallet = multiwallets[i];
    if (wallets.includes(wallet)) {
      continue;
    }
    else {
      wallets.push(wallet.name + ":" + wallet.address);
    }
  }

  localStorage.setItem('walletList', wallets.join(','));
}


let addOrUpdateWalletToStorage = (wallet) => {
  const walletList = localStorage.walletList || '';
  const wallets = walletList ? walletList.split(',') : [];

  const existingWalletIndex = wallets.findIndex((w) => w.split(':')[0] === wallet.address || w.split(':')[1] === wallet.address);
  if (existingWalletIndex !== -1) {
    // If the wallet already exists, update it with the new value
    wallets[existingWalletIndex] = wallet.name + ':' + wallet.address;
  } else {
    // Otherwise, add the new wallet to the array
    wallets.push(wallet.name + ':' + wallet.address);
  }

  localStorage.setItem('walletList', wallets.join(','));
};

function removeWalletFromStorage(wallet) {
    const walletList = localStorage.walletList || '';
    const wallets = walletList ? walletList.split(',') : [];

    let index = -1;
    let i = 0;
    wallets.forEach(w=>{
      if(w.includes(wallet.address)){
        index= i;
      }
      i++;
    })

    if (index !== -1) {
      wallets.splice(index, 1);
      localStorage.setItem('walletList', wallets.join(','));
    }
}

function getWalletsFromStorage() {
  const walletList = localStorage.walletList || '';
  const wallets = walletList ? walletList.split(',') : [];
  const walletObjects = [];
  let duplicatesFound = false;
  

  for (let i = 0; i < wallets.length; i++) {
    const wallet = wallets[i];

    if (wallet) {
      const walletObj = wallet.split(":")
      let walletName = "";
      let walletAddr = "";
      if(walletObj.length > 1) {
        walletName = walletObj[0]
        walletAddr = walletObj[1]
      }
      else {
        walletName = `${walletObj[0].slice(0, 5)}...${walletObj[0].slice(-4)}`
        walletAddr = walletObj[0];
      }

      const walletObject = {
        id: i + 1,
        name: walletName,
        address: walletAddr
      };

      let duplicate = false;
      walletObjects.forEach(e=>{
        if(e.address == walletAddr) {
          duplicate = true;
          duplicatesFound = true;
        }
      })

      if(!duplicate) {
        walletObjects.push(walletObject);
      }
    }
  }


  // add optional code to remove erroneous wallets
  // by replacing the wallets session data

  return walletObjects;
}

function getTokensFromStorage() {
  const tokenList = localStorage.tokenList || '';
  const tokens = tokenList ? tokenList.split(',') : [];
  const tokenObjects = [];
  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    if (token) {
      const tokenAddress = token.split(":")

      const tokenObject = {
        id: i + 1,
        address: tokenAddress[1],
        name: tokenAddress[0],
        nomics_id: tokenAddress[2],
        platform: tokenAddress[3] ? tokenAddress[3] : "ETH"
      };
      tokenObjects.push(tokenObject);
    }
  }

  if(tokenObjects.length < 1){
    const defaultObjects = [
        { id: 1, name: "HEX", address: "0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39", nomics_id: "HEX", platform: "ETH"},
        { id: 2, name: "HDRN", address: "0x3819f64f282bf135d62168C1e513280dAF905e06", nomics_id: "HDRN", platform: "ETH"},
        { id: 3, name: "ICSA", address: "0xfc4913214444aF5c715cc9F7b52655e788A569ed", nomics_id: "ICSA", platform: "ETH"},
        { id: 4, name: "HEX", address: "0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39", nomics_id: "pHEX", platform: "PLS"},
        { id: 5, name: "HDRN", address: "0x3819f64f282bf135d62168C1e513280dAF905e06", nomics_id: "pHDRN", platform: "PLS"},
        { id: 6, name: "ICSA", address: "0xfc4913214444aF5c715cc9F7b52655e788A569ed", nomics_id: "pICSA", platform: "PLS"},
        { id: 7, name: "PLSX", address: "0x95B303987A60C71504D99Aa1b13B4DA07b0790ab", nomics_id: "pPLSX", platform: "PLS"},
        { id: 8, name: "INC", address: "0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d", nomics_id: "pINC", platform: "PLS"},
        { id: 9, name: "DAI", address: "0xefd766ccb38eaf1dfd701853bfce31359239f305", nomics_id: "pDAI", platform: "PLS" }]
    defaultObjects.forEach(item=>{
      addTokenToStorage(item)
    })
    return defaultObjects;
  }

  return tokenObjects;
}

let addTokenToStorage = (token) => {
  const tokenList = localStorage.tokenList || '';
  const tokens = tokenList ? tokenList.split(',') : [];
  if (tokens.includes(token)) {
    return;
  }
  tokens.push(token.name + ":" + token.address + ":" + token.nomics_id + ":" + token.platform);
  localStorage.setItem('tokenList', tokens.join(','));
}

function removeTokenFromStorage(token) {
  const tokenList = localStorage.tokenList || '';
  const tokens = tokenList ? tokenList.split(',') : [];

  let index = -1;
  let i = 0;
  tokens.forEach(t=>{
    if(t.includes(token.address)){
      index= i;
    }
    i++;
  })

  if (index !== -1) {
    tokens.splice(index, 1);
    localStorage.setItem('tokenList', tokens.join(','));
  }
}

function setThemeToStorage(theme) {
  localStorage.setItem('theme', theme);
}

function getThemeFromStorage() {
  const theme = localStorage.theme || '0';
  return theme;
}

function getUpdateStatus() {
  const updated = localStorage.updated || '0';
  return updated;
}
function setUpdateStatus(val) {
  localStorage.setItem('updated', val);
}

export { getThemeFromStorage, setThemeToStorage }
export { getSession }
export { saveBalancesToStorage, getBalancesFromStorage }
export { addTokenToStorage, getTokensFromStorage, removeTokenFromStorage }
export { addMultipleWalletsToStorage, addWalletToStorage, removeWalletFromStorage, getWalletsFromStorage, addOrUpdateWalletToStorage}

export { getUpdateStatus, setUpdateStatus}