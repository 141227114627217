import axios from "axios";
import { REACT_APP_API_LOCALHOST, REACT_APP_API_NOMICS, REACT_APP_API_NOMIC2 } from "../Services/serviceHelper";
import { getSession } from "../Components/Functional/DataStorage/DataManager";

const localhost = window.location.hostname === "localhost" ? true : false;

const walletService = {
    endpoint: (localhost ? REACT_APP_API_LOCALHOST : "https://www.hsiwatch.com") + "/api/wallet"
}

walletService.getStakes = (wallets) => {
    const config = {
        method: "PUT",
        url: `${walletService.endpoint}/stakes`,
        crossdomain: true,
        withCredentials: false,
        data: wallets,
        headers: {
            "Accept": 'application/json'
        },
    }
    return axios(config);
}

walletService.getWalletData = (wallets) => {

    const config = {
        method: "PUT",
        url: `${walletService.endpoint}/data`,
        crossdomain: true,
        withCredentials: false,
        data: wallets,
        headers: {
            "Accept": 'application/json',
        },
    }
    return axios(config);
}

walletService.getIcosaStakes = (wallets) => {

    const config = {
        method: "PUT",
        url: `${walletService.endpoint}/icosastakes`,
        crossdomain: true,
        withCredentials: false,
        data: wallets,
        headers: {
            "Accept": 'application/json',
        },
    }
    return axios(config);
}



// walletService.getBalances = (payload) => {
//     const config = {
//         method: "PUT",
//         url: `${walletService.endpoint}/balances`,
//         crossdomain: true,
//         withCredentials: false,
//         data: payload,
//         headers: {
//             "Accept": 'application/json',
//         },
//     }
//     return axios(config);
// }

export default walletService;