import React from "react";

export function IcosaTooltip(){
    return (<div className="container">
        <div className="row">
            <div className="col">
                ICSA APY Formula
            </div>
        </div>
        <div className="row">
            <div className="col-5">
                <div>ICSA + HDRN</div>
                <div>Mining Yield</div>
            </div>
            <div className="col"></div>
            <div className="col-5">
                365
            </div>
        </div>
        <div className="row">
            <div className="col-5"><div className="line"/></div>
            <div className="col">x</div>
            <div className="col-5"><div className="line"/></div>
        </div>
        <div className="row">
            <div className="col-5">
                <div>Icosa + HDRN</div>
                <div>Principle in ICSA Value</div>
            </div>
            <div className="col"></div>
            <div className="col-5">
                <div>Average Days Served</div>
                <div>Across Miners</div>
            </div>
        </div>
    </div>)
} 


export function ShareValueTooltip(){
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    HEX share value is equal to the total t-shares multiplied by the t-share rate in HEX.
                </div>
            </div>
        </div>
    )
} 