import DragAndDropArea from "Components/Functional/DragAndDropList/DragAndDropArea";
import TextInputField from "Components/Functional/Input/TextInputField";
import React, { useState }  from "react";
import { contractSymbolOf2 } from "../../Functional/Web3/ContractInteraction";
import quoteService from "../../../Services/quoteService";
import { addTokenToStorage, removeWalletFromStorage, getWalletsFromStorage } from "../../Functional/DataStorage/DataManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import TokenSettings from "./TokenSettings";

function TokenWatchList ({provider, pulseProvider, items, selectedItem, selectedCurrency, quotes, handleUpdateItems, handleSelectItem, loading}) {
    const [settingsOpen, setSettingsOpen] = useState(false);

    const handleOnClick = (event, item) => {
        // When a token is clicked perform an action
        console.log("Clicked on item id " + item.id);
        if(selectedItem !== null) {
            if(selectedItem.id === item.id)
                handleSelectItem(null)
                //setSelectedAddress(null);
            else
                handleSelectItem(item)
                //setSelectedAddress(item);
        }
        else
            handleSelectItem(item);
            //setSelectedAddress(item);
    }

    const _handleUpdateItems = (items) => {

        if(handleUpdateItems !== null && handleUpdateItems !== undefined)
            handleUpdateItems(items);
    }

    const handleOnSubmit = async (value) => {
        let maxId = 0;
        for(let i = 0; i < items.length; i++) {
            // find max id for increment
            if(items[i].id > maxId)
                maxId = items[i].id

            if(items[i].address === value)
                return;
        }
        let newItems = [...items];
        if(items.length < 1) {
            maxId = 0;
        }

        await contractSymbolOf2(pulseProvider, value).then(r2=>{
            let symbol = r2;
            let nomics_id = r2;
            const tokenObject = {id: maxId + 1, address: value, name: symbol, nomics_id: nomics_id, platform: "PLS"};
            newItems.push(tokenObject);
            addTokenToStorage(tokenObject);
             _handleUpdateItems(newItems);
        });
        await contractSymbolOf2(provider, value).then(r2=>{
            if(r2 === "UNK") { return; }
            let symbol = r2;
            let nomics_id = r2;
            const tokenObject = {id: maxId + 1, address: value, name: symbol, nomics_id: nomics_id, platform: "ETH"};
            newItems.push(tokenObject);
            addTokenToStorage(tokenObject);
             _handleUpdateItems(newItems);
        })

        // await quoteService.getTokenSymbol(value).then(r=>{
        //     debugger;

        //     // only grab alpha characters
        //     let symbol = r.data.code.replace(/[^a-zA-Z]/g, '');
        //     let nomics_id = r.data.code;

        //     const tokenObject = {id: maxId + 1, address: value, name: symbol, nomics_id: nomics_id};
        //     newItems.push(tokenObject);
    
        //     addTokenToStorage(tokenObject);
        //     _handleUpdateItems(newItems);
        // }).catch(e=>{

        // })
    }

    function sortByPlatform(objects) {
        let pls = objects.filter(f=>f.platform==="PLS")
        let eth = objects.filter(f=>f.platform==="ETH")

        let newOrder = [];

        eth.forEach(e=>newOrder.push(e))
        pls.forEach(p=>newOrder.push(p))

        // objects.sort((a, b) => {
        //   var platformA = a.platform.toUpperCase();
        //   var platformB = b.platform.toUpperCase();

        //   var id1 = a.id;
        //   var id2 = v.id;

        //   if(id1 < id2) return 1
        //   if(id1 > id2) return -1
        //   return 0
        // });
      
        return newOrder;
    }
    
    let nowDate = new Date();

    return(
        <div className="tokens-watchlist">
            <div className="left-nav-header">
                Watchlist
            </div>
            
            <div className="t-muted">
                <small style={{fontSize: '10px'}}>
                    Click and drag to rearrange
                </small>
                <div className="settings-button-token" onClick={()=>{setSettingsOpen(!settingsOpen)}}>
                    <FontAwesomeIcon icon={faGear}/>
                </div>
                <TextInputField 
                    placeholder="Add token or contract..." 
                    handleOnSubmit={handleOnSubmit}
                    requireAddress={true}
                />
                {/* <DragAndDropArea 
                    items={items} 
                    reference={quotes} 
                    reference2={selectedCurrency}
                    loadingReference={loading}
                    handleOnClick={handleOnClick} 
                    handleUpdateItems={_handleUpdateItems} 
                    selected={selectedItem}
                    showTicker={true}
                    /> */}
                {items?.length > 0 && !settingsOpen && 
                    <DragAndDropArea 
                        items={sortByPlatform(items)}//{items} 
                        reference={quotes} 
                        reference2={selectedCurrency}
                        loadingReference={loading}
                        handleOnClick={handleOnClick} 
                        handleUpdateItems={_handleUpdateItems} 
                        selected={selectedItem}
                        showTicker={true}
                        />
                        }

                {items?.length > 0 && settingsOpen && 
                    <TokenSettings
                        items={items}
                        quotes={quotes}
                        handleUpdateItems={_handleUpdateItems} 
                    />

                }
                <div className="do-follow-nomics">
                    Updated: {nowDate.toLocaleString()}
                    <br/><a href="www.livecoinwatch.com" alt="Alas, our time together was too short">Live price data provided by LiveCoinWatch</a>
                </div>
            </div>
        </div>
    )
}

export default TokenWatchList;