import React, {useEffect, useState} from "react";
import { Web3Provider } from '@ethersproject/providers';
import { REACT_APP_API_INFURA } from "Services/serviceHelper";

//import { ethers } from 'ethers';
const ethers = require("ethers");


function EthersWrapper({ children }) {
    const [provider, setProvider] = useState(null);
    const [pulsechainProvider, setPulsechainProvider] = useState(null);
    
    useEffect(() => {
      // Create a new provider using Metamask and Infura
      async function init() {

        if (window.ethereum) {
          // Use this line to connect a wallet;
          //await window.ethereum.request({ method: 'eth_requestAccounts' });

          //const metamaskProvider = new ethers.providers.Web3Provider(window.ethereum);
          // const metamaskProvider = new Web3Provider(window.ethereum);
          // setProvider(metamaskProvider);
          // setPulsechainProvider(metamaskProvider);

          //const infuraProvider = ethers.getDefaultProvider('https://mainnet.infura.io/v3/'+REACT_APP_API_INFURA);//ethers.InfuraProvider.getWebSocketProvider('homestead',REACT_APP_API_INFURA);
          // const infuraProvider = new ethers.providers.Web3Provider(window.ethereum);
          const infuraProvider = ethers.getDefaultProvider('https://mainnet.infura.io/v3/'+REACT_APP_API_INFURA);//ethers.InfuraProvider.getWebSocketProvider('homestead',REACT_APP_API_INFURA);
          const pulseProvider = ethers.getDefaultProvider("https://rpc.pulsechain.com"); // RPC Testnet V4
          setProvider(infuraProvider);
          setPulsechainProvider(pulseProvider);

          console.log('Metamask installed in browser');
        } else {
          
          const infuraProvider = ethers.getDefaultProvider('https://mainnet.infura.io/v3/'+REACT_APP_API_INFURA);//ethers.InfuraProvider.getWebSocketProvider('homestead',REACT_APP_API_INFURA);
          const pulseProvider = ethers.getDefaultProvider("https://rpc.pulsechain.com"); // RPC Testnet V4

          //  const provider = new ethers.FallbackProvider([
          //    infuraProvider
          //  ]);
          setProvider(infuraProvider);
          setPulsechainProvider(pulseProvider);

          console.log('Metamask not installed in browser');
        }
      }
      init();
    }, []);
  
    return (
        <div className="height-max">
            {/* {provider && React.cloneElement(children, { provider })} */}
            {React.cloneElement(children, { provider, pulsechainProvider })}
        </div>
    );
  }

  export default EthersWrapper;