import axios from "axios";
import { REACT_APP_API_LOCALHOST } from "../Services/serviceHelper";

const localhost = window.location.hostname === "localhost" ? true : false;

const dataService = {
    endpoint2: (localhost ? REACT_APP_API_LOCALHOST : "https://www.hsiwatch.com") + "/api/wallet"
}

dataService.getDailyFull = () => {
    const config = {
        method: "GET",
        url: `${dataService.endpoint2}/hexdaily`,
        crossdomain: true,
        withCredentials: false,
        headers: {"Content-Type": "application/json"},
    }
    return axios(config);
}

/*
dataService.getDailyLive = () => {
    const config = {
        method: "GET",
        url: `${dataService.endpoint}livedata`,
        crossdomain: true,
        // withCredentials: true,
    headers: {"Content-Type": "application/json","Access-Control-Allow-Credentials": true},
    }
    return axios(config);
}
*/

export default dataService;