import React from "react"

export default class Auctions extends React.Component {



    render() {
        return (
            <div>
                Auction Dashboard!
            </div>
        )
    }
}