import React, { useState } from 'react';

function MaintenanceModal() {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    // Do something when user confirms
    handleClose();
  };

  return (
    <div className={`modal ${isOpen ? 'open' : ''}`} onClick={handleClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={handleClose}>X</button>
        <div className="modal-title"><h2>Maintenance Notice</h2></div>
        <p><br/><span className="subheader">2023-04-10:</span><br/><br/>
        HSIWatch is currently down for server maintenance and should be up within the next day or so.<br/><br/>
        My apologies for any inconvenience this may cause and I appreciate your understanding while I get things up and running again.
        
        <br/><br/>Sincerly,
        <br/>Juyoung the Hexican
        </p>
        <button className="modal-confirm" onClick={handleConfirm}>Confirm</button>
      </div>
    </div>
  );
}

export default MaintenanceModal;