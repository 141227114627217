import React, {useState, useEffect} from "react";
import { sampleStakes } from "../../Functional/Web3/SampleData";
import { getAddressShortName, convertHeartsToHex } from "../../Functional/Web3/ContractInteraction";
import { shortenChainName, UTCtoLocalDateString, millisecondsToDays, getCurrentUTCTimestamp, daysToMilliseconds, nFormatter, exportToExcel } from "../../Functional/HelperFunctions";
import LoadImg from "../../../Art/Animated/loading.gif"
import MiningListItem from "./MiningListItem";
import { faDownload, faSortDown, faSortUp, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function MiningListCard({items, loading, wallets, selectedWallet, quotes, selectedCurrency}){
    const [section, setSection] = useState(0)
    const [sortByColumn, setSortByColumn] = useState(null)
    const [sort, setSort] = useState("");
    
    const [dots, setDots] = useState("");
    const [totals, setTotals] = useState(null);
    const [prevItems, setPrevItems] = useState([]);
    const [prevSelectedCurrency, setPrevSelectedCurrency] = useState(null);
    useEffect(() => {
        let interval = null;

        
        if(!items || items.length < 1) {
            setTotals(null);
        }
        else if( (items && !arrayEquals(items, prevItems)) || selectedCurrency !== prevSelectedCurrency) {
            try {
                let totals = getTotals(items);
                setTotals(totals);
                setPrevItems(items);
                setPrevSelectedCurrency(selectedCurrency);
            }
            catch {
                setTotals(null);
            }

        }
        
        
        if (dots.length < 4) {
            interval = setInterval(() => {
                setDots(dots => dots + ".");
            }, 1000);
            } else {
            setDots("");
            }

        return () => clearInterval(interval);
    }, [dots, items, selectedCurrency]);

    function arrayEquals(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
    }

      
    const getTotals = (newItems) => {
        if(!newItems) { return "-" }

        let totals = {
            shares: 0,
            value: 0,
            hexBurn: 0,
            hexMined: 0,
            hdrnMintable: 0,
            hdrnFuture: 0,
            adjustedApy: 0,
            prefix: "",

            pshares: 0,
            pvalue: 0,
            phexBurn: 0,
            phexMined: 0,
            phdrnMintable: 0,
            phdrnFuture: 0,
        }

        if(newItems.length < 1 || !newItems[0].results) {
            return totals;
        }

        newItems.forEach(i=>{

            let adjApy = 0;
            let hdrnInHexValue = 0;
        
            if(quotes && quotes.length > 0 && i.results && i.results.hdrnMintable){
                let hdrnId = i.chainId === "1" ? "HDRN" : "pHDRN"
                let hexId = i.chainId === "1" ? "HEX" : "pHEX"
                quotes.forEach(q=>{
                    let qId = `${hdrnId}_${hexId}`;
                    if(q.id === hdrnId && q[qId]) {
                        hdrnInHexValue = (i.results.hdrnMintable + i.results.hdrnMinted) * q[qId];
                    }
              })
            }
        

            adjApy = ((i.results.hexMined + hdrnInHexValue) / (i.results.hexPrinciple < 0 ? 0 : i.results.hexPrinciple)) * (365 / parseFloat(i.results.daysServed <= 0 ? 1 : i.results.daysServed))     
            adjApy = (adjApy * 100).toFixed(1);

            i.results.ajdApy = adjApy;
            
            totals.shares += parseFloat(i.stakeShares);

            if(i.chainId === "1") {
                totals.value += i.results.hexCombinedValue;
                totals.hexBurn += i.results.hexPrinciple;
                totals.hexMined += i.results.hexMined;
                totals.hdrnMintable += i.results.hdrnMintable;
                totals.hdrnFuture += i.results.hdrnFutureMintable;
            }
            else {
                //totals.pshares += parseFloat(i.stakeShares);
                totals.pvalue += i.results.hexCombinedValue;
                totals.phexBurn += i.results.hexPrinciple;
                totals.phexMined += i.results.hexMined;
                totals.phdrnMintable += i.results.hdrnMintable;
                totals.phdrnFuture += i.results.hdrnFutureMintable;
            }


            totals.adjustedApy += parseFloat(adjApy);
        })

        totals.adjustedApy = (totals.adjustedApy / newItems.length).toFixed(1) + "%"

        // convert to currency
        if(quotes && quotes.length > 0 && selectedCurrency !== null) {

            let hdrnId = `HDRN_${selectedCurrency.id}`;
            let hexId = `HEX_${selectedCurrency.id}`;
            let phdrnId = `pHDRN_${selectedCurrency.id}`;
            let phexId = `pHEX_${selectedCurrency.id}`;
            quotes.forEach((q) =>{
                if(q.id === "HDRN" && q[hdrnId]) {
                    //Add HDRN Code here
                    totals.hdrnMintable *= q[hdrnId];
                    totals.hdrnFuture *= q[hdrnId];
                }
                if(q.id === "HEX" && q[hexId]) {
                    totals.hexBurn  *= q[hexId];
                    totals.hexMined *= q[hexId];
                    totals.value *= q[hexId];
                }
                if(q.id === "pHDRN" && q[phdrnId]) {
                    //Add HDRN Code here
                    totals.hdrnMintable = totals.phdrnMintable * q[phdrnId];
                    totals.hdrnFuture = totals.phdrnFuture * q[phdrnId];
                }
                if(q.id === "pHEX" && q[phexId]) {
                    totals.hexBurn  = totals.phexBurn * q[phexId];
                    totals.hexMined = totals.phexMined * q[phexId];
                    totals.value = totals.pvalue * q[phexId];
                }
            })
        }

        if(selectedCurrency !== null) {
            if(selectedCurrency.id === "USDC") {
                totals.prefix  = "$";
            }
        }

        return totals;
    }


    // Return this if no miners have been provided yet
    let noMinersProvided = !items || !Array.isArray(items) || items.length < 1;

    let handleClickNextSection = () => {
        let lastSection = 1;
        let nextSection = section + 1 > lastSection ? 0 : section + 1;
        setSection(nextSection);
    }

    let sorter = (array) => {
        if(sort === "" || sortByColumn === null) {
            return array;
        }
      
        const newArray = array.slice();
      
        newArray.sort((a, b) =>
        sortByColumn === "" || sort === ""
            ? a.sort - b.sort
            : sortByColumn === "Cx"
            ? sort === "Ascending"
            ? a.chainName.localeCompare(b.chainName)
            : b.chainName.localeCompare(a.chainName)
            : sortByColumn === "Address"
            ? sort === "Ascending"
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name)
            : sortByColumn === "Type"
            ? sort === "Ascending"
            ? a.stakeType.localeCompare(b.stakeType)
            : b.stakeType.localeCompare(a.stakeType)
            : sortByColumn === "Start"
            ? sort === "Ascending"
            ? a.stakeStartTimestamp - b.stakeStartTimestamp
            : b.stakeStartTimestamp - a.stakeStartTimestamp
            : sortByColumn === "End"
            ? sort === "Ascending"
            ? a.results.endDay - b.results.endDay
            : b.results.endDay - a.results.endDay
            : sortByColumn === "Shares"
            ? sort === "Ascending"
            ? parseFloat(a.stakeShares) - parseFloat(b.stakeShares)
            : parseFloat(b.stakeShares) - parseFloat(a.stakeShares)
            : sortByColumn === "Value"
            ? sort === "Ascending"
            ? a.results.hexCombinedValue - b.results.hexCombinedValue
            : b.results.hexCombinedValue - a.results.hexCombinedValue
            : sortByColumn === "HEX Burn"
            ? sort === "Ascending"
            ? a.results.hexPrinciple - b.results.hexPrinciple
            : b.results.hexPrinciple - a.results.hexPrinciple
            : sortByColumn === "HEX Mine"
            ? sort === "Ascending"
            ? a.results.hexMined - b.results.hexMined
            : b.results.hexMined - a.results.hexMined
            : sortByColumn === "HDRN Mint"
            ? sort === "Ascending"
            ? a.results.hdrnMintable - b.results.hdrnMintable
            : b.results.hdrnMintable - a.results.hdrnMintable
            : sortByColumn === "HDRN Future"
            ? sort === "Ascending"
            ? a.results.hdrnFutureMintable - b.results.hdrnFutureMintable
            : b.results.hdrnFutureMintable - a.results.hdrnFutureMintable
            : sortByColumn === "Adj APY"
            ? sort === "Ascending"
            ? parseFloat(a.stakeShares) - parseFloat(b.stakeShares)
            : parseFloat(b.stakeShares) - parseFloat(a.stakeShares)
            : sort === "Ascending"
            ? a.stakeId - b.stakeId
            : b.stakeId - a.stakeId
        );
      
        return newArray;
    };
    
    let renderSortIcon = (column) => {
        if(column !== sortByColumn || sort === "") 
          return ""//<div className="sort-icon no-sort"><FontAwesomeIcon icon={faSort}/></div>
    
        switch(sort) {
          case "Ascending":
            return <div className="sort-icon"><FontAwesomeIcon icon={faSortDown}/></div>
          case "Descending":
            return <div className="sort-icon"><FontAwesomeIcon icon={faSortUp}/></div>
          default:
            return ""//<div className="sort-icon no-sort"><FontAwesomeIcon icon={faSort}/></div>
        }
    }

    let onHandleSort = (string) => {
        if( sortByColumn === string ) {
          if ( sort === "Descending") 
            setSort("Ascending")
          else if (sort === "Ascending") {
            setSort("")
            setSortByColumn("");
          }

          else 
            setSort("Descending")
        }
        else if (sortByColumn !== string) {
          setSortByColumn(string);
          setSort("Descending")
        }
        else 
          setSortByColumn("");
    }

    let mapItems = (item, index) =>{

        return (
            <MiningListItem 
                key={index} 
                section={section}
                item={item} 
                quotes={quotes}
                selectedCurrency={selectedCurrency}
                index={index}/>
            )
    }

    let displayCurrency = () =>{
        let displayText = ""
        if(selectedCurrency === null){
            return displayText;
        }
        else {
            displayText = " ("+ selectedCurrency.symbol +")";
            return (
                <small>
                    {displayText}
                </small>
            )

        }
    }

    let rcn = (_column) => {        
        let className = "";
        if( _column === sortByColumn)
            className += "sorted-column"
        
        return className;
    }

    let showOnSection = (_section) => {
        if(typeof _section == "number"){            
            let num = _section;
            _section = []
            _section.push(num);
        }        
        
        if(!_section.includes(section))
            return "d-none"
        return ""
    }


    // Return this if miners have been provided
    return(
        <div className="card miner-list-card">
            <div className="card-body">
                <div className="card title-card">
                    <div className="card">
                        <div className="miners-title">
                            <div>
                                <div>Active Miners {displayCurrency()}</div>
                                <div className="move-to-next-section-button" onClick={handleClickNextSection}>
                                        <FontAwesomeIcon icon={faArrowCircleRight}/>
                                </div>
                                <div className="custom-tooltip export-to-excel" onClick={()=>exportToExcel(["ActiveMiners"])}>
                                        <FontAwesomeIcon icon={faDownload}/><span className="tooltiptext">Export to Excel</span>
                                </div>
                                <div>
                                    <small className="miner-list-info">Click a column to sort</small>
                                    {selectedWallet && <small className="miner-list-info-2">* Filter Applied</small>}
                                </div>
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th className={showOnSection([0,1])+" "+rcn("Cx")} onClick={()=>onHandleSort("Cx")}>Cx{renderSortIcon("Cx")}</th>
                                    <th className={showOnSection([0,1])+" "+rcn("Address")} onClick={()=>onHandleSort("Address")}>Address{renderSortIcon("Address")}</th>
                                    <th className={showOnSection(0)+" "+rcn("Type")} onClick={()=>onHandleSort("Type")}>Type{renderSortIcon("Type")}</th>
                                    <th className={showOnSection(0)+" "+rcn("Start")} onClick={()=>onHandleSort("Start")}>Start{renderSortIcon("Start")}</th>
                                    <th className={showOnSection(0)+" "+rcn("End")} onClick={()=>onHandleSort("End")}>End{renderSortIcon("End")}</th>
                                    <th className={showOnSection(0)+" "+rcn("Shares")} onClick={()=>onHandleSort("Shares")}>Shares{renderSortIcon("Shares")}</th>
                                    <th className={showOnSection(0)+" "+rcn("Value")} onClick={()=>onHandleSort("Value")}>Value{renderSortIcon("Value")}</th>

                                    <th className={showOnSection(1)+" "+rcn("HEX Burn")} onClick={()=>onHandleSort("HEX Burn")}>HEX Burned{renderSortIcon("HEX Burn")}</th>
                                    <th className={showOnSection(1)+" "+rcn("HEX Mine")} onClick={()=>onHandleSort("HEX Mine")}>HEX Mined{renderSortIcon("HEX Mine")}</th>
                                    <th className={showOnSection(1)+" "+rcn("HDRN Mint")} onClick={()=>onHandleSort("HDRN Mint")}>HDRN Mintable{renderSortIcon("HDRN Mint")}</th>
                                    <th className={showOnSection(1)+" "+rcn("HDRN Future")} onClick={()=>onHandleSort("HDRN Future")}>HDRN Future{renderSortIcon("HDRN Future")}</th>
                                    <th className={showOnSection(1)+" "+rcn("Adj APY")} onClick={()=>onHandleSort("Adj APY")}>Adj. APY{renderSortIcon("Adj APY")}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>

                <div className="card miners-card-body">
                    <div className="card miners-card-body2">
                        {noMinersProvided && wallets && !loading && <div className="t-ac padding-t20 padding-b20">No active miners found.</div>}
                        {noMinersProvided && !wallets && !loading && <div className="t-ac padding-t20 padding-b20">Provide a wallet in the menu to get started.</div>}
                        {loading && <div className="loader"><img src={LoadImg}/><br/><br/>Loading{dots}</div>}
                        {!noMinersProvided && items.length > 0 && (!items[0].results) && <div className="loader"><img src={LoadImg}/><br/><br/>Calculating{dots}</div>}
                        {!noMinersProvided && items.length < 1 && <div className="t-ac padding-t20 padding-b20">No active miners found.</div>}
                        <table id='ActiveMiners'>
                            <thead style={{display: "none"}}>
                                    <tr>
                                        <th className={showOnSection([0,1])}>Chain</th>
                                        <th className={showOnSection([0,1])}>Address<span style={{display: "none"}}>{selectedCurrency ? " Alias" : ""}</span></th>
                                        <th className={showOnSection(0)}>Type</th>
                                        <th className={showOnSection(0)}>Start</th>
                                        <th className={showOnSection(0)}>End</th>
                                        <th className={showOnSection(0)}>Shares</th>
                                        <th className={showOnSection(0)}>Value<span style={{display: "none"}}>{selectedCurrency ? " ("+selectedCurrency.symbol+")" : ""}</span></th>

                                        <th className={showOnSection(1)}>HEX Burn</th>
                                        <th className={showOnSection(1)}>HEX Mined</th>
                                        <th className={showOnSection(1)}>HDRN Mintable</th>
                                        <th className={showOnSection(1)}>HDRN Future Mint</th>
                                        <th className={showOnSection(1)}>Adj. APY</th>

                                        <th style={{display: "none"}}>Address Full</th>
                                        <th style={{display: "none"}}>HSI Address</th>
                                        <th style={{display: "none"}}>Mining Progress (%)</th>
                                        <th style={{display: "none"}}>Value (HEX)</th>
                                        <th style={{display: "none"}}>Value <span style={{display: "none"}}>{selectedCurrency ? "("+selectedCurrency.symbol+")" : ""}</span></th>
                                        <th style={{display: "none"}}>HDRN Future Mint</th>
                                        <th style={{display: "none"}}>HDRN Lifetime Mint</th>
                                        <th style={{display: "none"}}>HDRN Current Mintable</th>
                                        <th style={{display: "none"}}>HDRN Minted</th>
                                    </tr>
                                </thead>
                            <tbody>                        
                                {!noMinersProvided && items && Array.isArray(items) && items.length > 0 && sorter(items).map(mapItems)}
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className="card miners-footer-card">
                    <div className="card">
                        <table>
                            <thead>
                                <tr>
                                    <th className={showOnSection([0,1])+" "+rcn("Cx")} onClick={()=>onHandleSort("Cx")}></th>
                                    <th className={showOnSection([0,1])+" "+rcn("Address")} onClick={()=>onHandleSort("Address")}></th>
                                    <th className={showOnSection(0)+" "+rcn("Type")} onClick={()=>onHandleSort("Type")}></th>
                                    <th className={showOnSection(0)+" "+rcn("Start")} onClick={()=>onHandleSort("Start")}></th>
                                    <th className={showOnSection(0)+" "+rcn("End")} onClick={()=>onHandleSort("End")}></th>
                                    <th className={showOnSection(0)+" "+rcn("Shares")} onClick={()=>onHandleSort("Shares")}>{totals ? nFormatter(totals.shares,2) : "-"}</th>
                                    <th className={showOnSection(0)+" "+rcn("Value")} onClick={()=>onHandleSort("Value")}>{totals ? totals.prefix + nFormatter(totals.value,2) : "-"}</th>

                                    <th className={showOnSection(1)+" "+rcn("HEX Burn")} onClick={()=>onHandleSort("HEX Burn")}>{totals ? totals.prefix + nFormatter(totals.hexBurn,2) : "-"}</th>
                                    <th className={showOnSection(1)+" "+rcn("HEX Mine")} onClick={()=>onHandleSort("HEX Mine")}>{totals ? totals.prefix + nFormatter(totals.hexMined,2) : "-"}</th>
                                    <th className={showOnSection(1)+" "+rcn("HDRN Mint")} onClick={()=>onHandleSort("HDRN Mint")}>{totals ? totals.prefix + nFormatter(totals.hdrnMintable,2) : "-"}</th>
                                    <th className={showOnSection(1)+" "+rcn("HDRN Future")} onClick={()=>onHandleSort("HDRN Future")}>{totals ? totals.prefix + nFormatter(totals.hdrnFuture,2) : "-"}</th>
                                    <th className={showOnSection(1)+" "+rcn("Adj APY")} onClick={()=>onHandleSort("Adj APY")}>{totals ? totals.adjustedApy : "-"}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default MiningListCard;