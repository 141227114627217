import * as XLSX from 'xlsx';
import PulsechainIcon from "../../Art/Tokens/Pulsechain_small.png"
import PLXIcon from "../../Art/Tokens/PLSX.png"
import INCIcon from "../../Art/Tokens/INC.png"
import DAIIcon from "../../Art/Tokens/dai.webp"
import ETHIcon from "../../Art/Tokens/eth.png"

export const tokenImgs = {
  PLS: PulsechainIcon,
  PLSX: PLXIcon,
  INC: INCIcon,
  DAI: DAIIcon,
  pDAI: DAIIcon,
  ETH: ETHIcon
}

function millisecondsToUTCDate(utcTimestamp) {
    // Create a new Date object from the given milliseconds
    const date = new Date(utcTimestamp * 1000);
    // Use the UTC methods to get the UTC year, month, day, etc.
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const millisecondsUTC = date.getUTCMilliseconds();
    // Return a new Date object representing the UTC date
    return new Date(Date.UTC(year, month, day, hours, minutes, seconds, millisecondsUTC));
  }

  function millisecondsToUTCDateString(utcTimestamp) {
    // Create a new Date object from the given milliseconds
    const date = new Date(utcTimestamp * 1000);
    // Use the UTC methods to get the UTC year, month, and day
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // Add 1 to the month to convert from zero-indexed to one-indexed
    const day = date.getUTCDate();
    // Construct the date string in the "YYYY-MM-DD" format
    const dateString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    // Return the date string
    return dateString;
  }

  function UTCtoLocalDateString(utcTimestamp) {
    // Create a new Date object from the UTC timestamp
    const dateUTC = new Date(utcTimestamp * 1000);
    // Convert the UTC date to local time
    const dateLocal = new Date(dateUTC.getTime() + dateUTC.getTimezoneOffset() * 60 * 1000);
    // Use the local methods to get the local year, month, and day
    const year = dateLocal.getFullYear();
    const month = dateLocal.getMonth() + 1; // Add 1 to the month to convert from zero-indexed to one-indexed
    const day = dateLocal.getDate();
    // Construct the date string in the "YYYY-MM-DD" format
    const dateString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    // Return the date string
    return dateString;
  }

  function UTCtoLocalDateTimeString(utcTimestamp) {
    // Create a new Date object from the UTC timestamp
    const dateUTC = new Date(utcTimestamp * 1000);
    // Convert the UTC date to local time
    const dateLocal = new Date(dateUTC.getTime() + dateUTC.getTimezoneOffset() * 60 * 1000);
    // Use the local methods to get the local year, month, day, hours, and minutes
    const year = dateLocal.getFullYear();
    const month = dateLocal.getMonth() + 1; // Add 1 to the month to convert from zero-indexed to one-indexed
    const day = dateLocal.getDate();
    const hours = dateLocal.getHours();
    const minutes = dateLocal.getMinutes();
    // Determine whether it's AM or PM
    const amPM = hours >= 12 ? 'PM' : 'AM';
    // Convert to 12-hour time
    const hours12 = hours % 12 || 12;
    // Construct the date string in the "YYYY-MM-DD hh:mm AM/PM" format
    const dateTimeString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${amPM}`;
    // Return the date string
    return dateTimeString;
  }

  function getCurrentUTCTimestamp() {
    return Date.now();
  }

  
function daysToMilliseconds(days) {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return days * millisecondsPerDay;
}

function millisecondsToDays(milliseconds){
  return (milliseconds / (1000 * 60 * 60 * 24)).toFixed(0);
}

function getDateFromHexDay(hexDay) {

  /*
  const startDate = new Date(2020, 0, 1); // January 1, 2022
  const date = new Date(startDate.getTime() + (hexDay - 1) * 24 * 60 * 60 * 1000);
  return date;
  */
  const startDate = new Date(Date.UTC(2019, 11, 5)); // January 1, 2022
  const date = new Date(startDate.getTime() + (hexDay - 1) * 86400000);
  return date;
}

function shortenNumberToString(number) {
  const suffixes = ['', 'k', 'm', 'b', 't', 'q', 'Q', 's', 'S', 'o', 'n', 'd'];
  const suffixNum = Math.floor(String(number).length / 3);
  let shortNum = parseFloat((suffixNum !== 0 ? (number / Math.pow(1000, suffixNum)) : number).toPrecision(3));
  if (shortNum % 1 !== 0) {
    shortNum = shortNum.toFixed(2);
  }
  if (suffixNum >= suffixes.length || (suffixNum === suffixes.length - 1 && number >= 1000000000000)) {
    suffixNum++;
    shortNum /= 1000;
  }
  return shortNum + suffixes[suffixNum];
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function nFormatter(num, digits) {
  if (typeof num !== "number") {
      try {
          num = parseFloat(num);
      } catch {
          return "0"
      }
  }

  if(num ==="0" || num === 0){
    return 0
  }

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "q" },
    { value: 1e18, symbol: "Q" },
    { value: 1e21, symbol: "s" },
    { value: 1e24, symbol: "S" },
    { value: 1e27, symbol: "o" },
    { value: 1e30, symbol: "n" },
    { value: 1e33, symbol: "d" },
    { value: 1e36, symbol: "U" },
    { value: 1e39, symbol: "D" },

  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });

  if(!item) return num.toFixed(3);

  let result = item ? (num / item.value).toFixed(digits).replace(rx, "$1") : 0;
  result = item ? parseFloat(numberWithCommas(result)) : 0;
  result = num < 1000 ? parseFloat(result).toFixed(0) : parseFloat(result).toFixed(digits) + item.symbol;
  //return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  return result;
}

function shortenChainName(chainname){
  if(chainname === "Ethereum Mainnet")
    return "Eth"
  else if(chainname === "PulseChain Mainnet")
    return "Pls"
  else return chainname;
}

function getSignificantDigits(num) {

  if(num < 1000) {
    let trailingZeros = countTrailingZeros(num)
    return num.toFixed(trailingZeros + 2);  
  }
  else{
    return num.toFixed(0)
  }
}

function countTrailingZeros(num) {
  // Convert the number to a string
  let numStr = num.toString();
  
  // Use a regular expression to match the trailing zeros
  let match = numStr.split('');
  let numberOfZeros = 0;
  let complete = false;
  match.forEach((n)=>{
    if(n==="0" && !complete) numberOfZeros++;
    else if(n==='.') numberOfZeros=0;
    else complete = true;
  })
  
  // If there is a match, return the length of the match
  return numberOfZeros;
}

function exportToExcel(tableIds) {
  if(!Array.isArray(tableIds)) {tableIds = [tableIds]}

  let worksheets = [];
  for(let i = 0; i < tableIds.length; i++) {
    // Select the table element using its ID
    const table = document.getElementById(tableIds[i]);
    // Convert the table HTML to a worksheet object
    const worksheet = XLSX.utils.table_to_sheet(table);

    worksheets.push(worksheet);
  }


  // Create a workbook object and add the worksheet to it
  const workbook = XLSX.utils.book_new();
  for(let i = 0; i < worksheets.length; i++) {
    XLSX.utils.book_append_sheet(workbook, worksheets[i], tableIds[i]);
  }

  // Convert the workbook to a binary Excel file and download it
  const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
  saveExcelFile(excelFile, 'hsiwatch-output.xlsx');
}

function saveExcelFile(data, filename) {
  const blob = new Blob([s2ab(data)], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
  URL.revokeObjectURL(url);
}

function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xFF;
  }
  return buf;
}

export {getCurrentUTCTimestamp,millisecondsToDays, daysToMilliseconds, millisecondsToUTCDate, millisecondsToUTCDateString, UTCtoLocalDateString, UTCtoLocalDateTimeString};
export {shortenNumberToString, nFormatter, numberWithCommas, getSignificantDigits }
export {shortenChainName, getDateFromHexDay}
export {exportToExcel}