import DragAndDropArea from "Components/Functional/DragAndDropList/DragAndDropArea";
import React, {useState, useEffect} from "react";
import TextInputField from "Components/Functional/Input/TextInputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

import { getAddressShortName, isValidWallet, handleGetHEXStakes} from "../../Functional/Web3/ContractInteraction";
import { addWalletToStorage, removeWalletFromStorage, getWalletsFromStorage, addMultipleWalletsToStorage } from "../../Functional/DataStorage/DataManager";
import RenameWallets from "./RenameWallets";

function WalletWatchList ({items, mobileMenuOpen, tokens, selectedToken, handleUpdateItems, selectedItem, quotes, selectedCurrency, balances, handleSelectItem}) {
    const [settingsOpen, setSettingsOpen] = useState(false);

    useEffect(() => {
        setSettingsOpen(false);
      }, [mobileMenuOpen]);

    const handleOnClick = (event, item) => {
        // When a token is clicked perform an action
        console.log("Clicked on item id " + item.id);
        if(selectedItem !== null) {
            if(selectedItem.id === item.id)
                handleSelectItem(null);
            else
                handleSelectItem(item);
        }
        else
            handleSelectItem(item);
    }

    const _handleUpdateItems = (items) => {
        if(handleUpdateItems !== null && handleUpdateItems !== undefined) {
            handleUpdateItems(items);
        }

    }
    
    const handleOnSubmit = (value) => {
        let maxId = 0;
        for(let i = 0; i < items.length; i++) {
            if(items[i].id > maxId)
                maxId = items[i].id
        }
        let newItems = [...items];
        if(items.length < 1) {
            maxId = -1;
        }

        let itemsToStore = []
        for(let i = 0; i < value.length; i++) {
            let currentWallet = value[i];
            if (newItems.some((item) => item.address.toUpperCase() === currentWallet.toUpperCase())) {
                continue;
            }

            let newWallet ={
                id: maxId + 1
                , name: getAddressShortName(currentWallet)
                , address: value[i]
            };
            maxId += 1;

            itemsToStore.push(newWallet);
            newItems.push(newWallet);
        }

        //newItems.push(newWallet)
        addMultipleWalletsToStorage(itemsToStore);
        //addWalletToStorage(newWallet);
        _handleUpdateItems(newItems);
        setSettingsOpen(false);
    }

    return(
        <div className="wallets-watchlist">
            <div className="left-nav-header">
                Wallets
            </div>
            <div className="t-muted pos-rel">

                <small style={{fontSize: '10px'}}>
                    Click to filter by wallet
                </small>
                <div className="settings-button" onClick={()=>{setSettingsOpen(!settingsOpen)}}>
                    <FontAwesomeIcon icon={faGear}/>
                </div>
                <TextInputField 
                    placeholder="Add wallet..." 
                    handleOnSubmit={handleOnSubmit}
                    requireAddress={true}
                    commaDelimited={true}
                />
                {items?.length < 1 && <div style={{fontSize: '12px', textAlign: 'center', color: 'white', paddingBottom: '20px', paddingTop: '10px'}}>Add an address above to get started</div>}
                {items?.length > 0 && !settingsOpen && 
                    <DragAndDropArea 
                        items={items} 
                        showBalance={true}
                        reference={quotes} 
                        reference2={selectedCurrency}
                        reference3={balances}
                        reference4={selectedToken}
                        handleOnClick={handleOnClick} 
                        handleUpdateItems={_handleUpdateItems} 
                        selected={selectedItem}/>}

                {items?.length > 0 && settingsOpen && 
                    <RenameWallets
                        items={items}
                        handleUpdateItems={_handleUpdateItems} 
                    />
                }
            </div>
            <div style={{textAlign: "right", width: "100%", paddingRight: "10px"}}>
                <small style={{fontSize: '10px', color:"var(--muted-text-color)", textAlign: "right"}}>
                    * Balances update 5 minutes
                </small>
            </div>
        </div>
    )
}

export default WalletWatchList;