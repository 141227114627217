import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDollarToSlot } from "@fortawesome/free-solid-svg-icons";
import PatreonIcon from "../../Art/Brands/patreon_white.png";
import TwitterIcon from "../../Art/Brands/twitter.png";

class SiteFooter extends React.Component {
    goToTwitter=()=>{
      window.open("https://twitter.com/HunterFX211", "_blank");
    }

    goToPatreon=()=>{
      window.open("https://www.patreon.com/hsiwatch", "_blank");
    }

    copyToClipboard=()=>{
        let walletAddress = "0x761B933545b52A1c3CD9c1E6478D9859f38A38f4"
        // Create a temporary input element
        var tempInput = document.createElement("input");
      
        // Set the input element's value to the wallet address
        tempInput.value = walletAddress;
      
        // Append the input element to the body
        document.body.appendChild(tempInput);
      
        // Select the input element's contents
        tempInput.select();
      
        // Copy the selected contents to the clipboard
        document.execCommand("copy");
      
        // Remove the temporary input element from the body
        document.body.removeChild(tempInput);
      
        // Display a message to the user indicating that the wallet address has been copied to the clipboard
        alert("Donation address copied to clipboard: " + walletAddress);
    }
    
    render() {
      return (
        <div className="site-footer">

          <div className="container">

            <div className="row">
              <div className="col">

              </div>
            </div>
          </div>

          <div className="disclaimer">
            <div className="site-footer-logo">
                  HSIWatch<span>.com</span>
            </div>
            <div>
              <div className="footer-buttons">
                  <div className="patreon-image" onClick={this.goToPatreon}>
                    <img src={PatreonIcon}/><small>Patreon</small>
                  </div>
                  <div className="donate-image" onClick={this.copyToClipboard}>
                    <FontAwesomeIcon icon={faCircleDollarToSlot}/><small>Donate</small>
                  </div>
                  <div className="twitter-image" onClick={this.goToTwitter}>
                    <img src={TwitterIcon}/><small>Twitter</small>
                  </div>
                </div>
            </div>
            This website is for information purposes only and is not intended to be financial or investment advice.<br/>
            All content is provided "as is" and without warranties of any kind, either express or implied.
          </div>

        </div>
)
    }
  }
  
  export default SiteFooter;