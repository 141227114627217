import axios from "axios";
import { REACT_APP_API_NOMICS, REACT_APP_API_NOMICS2 } from "./serviceHelper.js";
import { tokenImgs } from "../Components/Functional/HelperFunctions.js";

const quoteService = {
    endpoint: `https://api.livecoinwatch.com`
    ,endpoint2: `https://graph.pulsechain.com/subgraphs/name/pulsechain/pulsex`
}


quoteService.getPulseCurrency = async (currencies) => {
    if(!currencies && currencies.length < 1) {
        return
    }
    
    let queryString = "[";

    currencies.forEach(c=>{
        queryString += "\""+c.name + "\""+ ","
    })
    queryString = queryString.slice(0, -1) + "]";
    
    let payload = {
        query: `query { tokens(where: { symbol_in: ${queryString} }) { id symbol derivedUSD derivedPLS totalSupply totalLiquidity } }`
    } 

    const config = {
        method: "POST",
        url: `${quoteService.endpoint2}`,
        crossdomain: true,
        withCredentials: false,
        allowCredentials: false,
        data: payload,
        headers: {
            "Content-Type": "application/json"
        },
    }
    return axios(config);
}


quoteService.getCurrency = async (currencies) => {
    if(!currencies.includes("ETH")) currencies += ",ETH"

    let payload = {
        codes: currencies.split(','),
        currency: "USD",
        limit: 100,
        meta: true
    }

    const config = {
        method: "POST",
        url: `${quoteService.endpoint}/coins/map`,
        crossdomain: true,
        withCredentials: false,
        allowCredentials: false,
        data: payload,
        headers: {
            "Content-Type": "application/json"
            ,"X-Api-Key": REACT_APP_API_NOMICS2//REACT_APP_API_NOMICS   
        },
    }
    return axios(config);
}

quoteService.getTokenSymbol = (payload) => {

    let _pay = { 
        currency: "USD", 
        platform: "ETH", 
        address: payload,
        meta: true
    };
    const config = {
        method: "POST",
        url: `${quoteService.endpoint}/coins/contract`,
        crossdomain: true,
        data: _pay,
        withCredentials: false,
        headers: {
            "Accept": 'application/json',
            "X-Api-Key": REACT_APP_API_NOMICS   
        },
    }
    return axios(config);
}

quoteService.parseCurrencies = (quotes) => {
    const prices = {};
    for (let i = 0; i < quotes.length; i++) {
        prices[quotes[i].code] = quotes[i];
    }

    for (let i = 0; i < quotes.length; i++) {
        if(quotes[i].code && quotes[i].code === "__MAXI"){
            quotes[i].code = "MAXI";
        }        
    }



    const newQuotes = [];
    for (let i = 0; i < quotes.length; i++) {
        const obj = quotes[i];
        const currencyId = obj.code; //obj.platform == "PLS" ? "p"+obj.code :obj.code;

        

        for (const otherCurrencyId in prices) {
            try {

            
                if (currencyId === otherCurrencyId) continue;
                const otherPrice = prices[otherCurrencyId].rate;

                const rateName1 = `${currencyId}_${otherCurrencyId}`;
                const rateName2 = `${otherCurrencyId}_${currencyId}`;
                const rate1 = obj.rate / otherPrice;
                const rate2 = otherPrice / obj.rate;

                obj[rateName1] = rate1;
                obj[`${currencyId}_USDC`] = parseFloat(obj.rate);

                /*
                let otherObj = prices[otherCurrencyId];
                if (!otherObj.hasOwnProperty(rateName2)) {
                    otherObj[rateName2] = rate2;
                    otherObj[`${currencyId}_${otherCurrencyId}`] = rate2;
                    otherObj[`${otherCurrencyId}_USDC`] = otherObj.price;
                    newQuotes.push(otherObj);
                }
                */

                // set id used by system
                obj.nomics_id = obj.code;//"PLS" ? "p"+obj.code.replace(/[^a-zA-Z]/g, '') : obj.code.replace(/[^a-zA-Z]/g, '');
                obj.id = obj.code;
                
                let symbol = obj.symbol ? obj.symbol.replace(/[^a-zA-Z]/g, '') : obj.code.replace(/[^a-zA-Z]/g, '');
    
                obj.name = symbol;
                obj.symbol = symbol;
                
                if(obj.png32) {
                    obj.logo_url = obj.png32 ? obj.png32 : "";
                }

                if(!obj.logo_url || obj.logo_url === "") {
                    try {
                        if(tokenImgs[obj.name]) {
                            obj.logo_url = tokenImgs[obj.name]
                        }
                        else {
                        for (const quote of quotes) {
                            if(quote.name === obj.name && quote.png32) {
                            obj.logo_url = quote.logo_url ? quote.logo_url : ""
                            break;
                            }
                        }
                        }
                    }
                    catch {
                        obj.logo_url = ""
                    }
                }

                obj.price = obj.rate;
            }
            catch {
                debugger;
            }
        }
        newQuotes.push(obj);
    }

    return newQuotes;
}

export default quoteService;