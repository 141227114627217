import React from 'react';
import { tokenImgs } from '../HelperFunctions';
import { nFormatter } from '../HelperFunctions';

const Marquee = ({quotes}) => {
  
  let getContent=(item, index)=>{
    if(!item || !item.nomics_id) return ""
    if(item.name === "INC" || item.name === "PLS" || item.name === "PLSX") return ""

    let price = item[`${item.nomics_id}_USDC`] ? item[`${item.nomics_id}_USDC`] : item[`${item.nomics_id}_pDAI`] 

    return (
      <div style={{display: "inline-block"}} key={index}>
        {item.logo_url && <img src={item.logo_url}/>}
        {price && <span>${price.toFixed(2)}</span>}
      </div>
    )
  }

  let staticContent=()=>{

    let plsx = 0;
    let pls = 0;
    let inc = 0;

    if(quotes) {
      quotes.forEach(q=>{
        if(!q.nomics_id) return;
        let price = q[`${q.nomics_id}_USDC`] ? q[`${q.nomics_id}_USDC`] : q[`${q.nomics_id}_pDAI`]
        if(q.name === "PLSX") plsx = price;
        if(q.name === "PLS") pls = price;
        if(q.name === "INC") inc = price;
      })
    }

    return (
      <span>
        <img src={tokenImgs["PLS"]}/><span>${pls.toFixed(5)}</span>
        <img src={tokenImgs["PLSX"]}/><span>${plsx.toFixed(6)}</span>
        <img src={tokenImgs["INC"]}/><span>${inc.toFixed(2)}</span>
      </span>
    )
  }

  return (
    <div className="marquee">
      <div className="marquee__static">{staticContent()}</div>
      <div className="marquee__content">
        {quotes && <div>ETH:{quotes.filter(f=>f.platform==="ETH").map(getContent)} PLS:{quotes.filter(f=>f.platform==="PLS").map(getContent)}</div>}
      </div>
    </div>
  );
};

export default Marquee;