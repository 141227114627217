import React, {useState, useEffect } from "react";
import { nFormatter } from "../../Functional/HelperFunctions";
import { convertHeartsToHex } from "Components/Functional/Web3/ContractInteraction";
import LoadingIcon from "../../../Art/Animated/loading.gif";
import Tooltip from "Components/Functional/Tooltip/Tooltip";
import { IcosaTooltip, ShareValueTooltip } from "Components/Functional/Tooltip/Tooltips/ToolTipInfo";

function MiningStatusCard({ shareRate, items, incAmount, loading, quotes, daily, selectedCurrency, icosas, hdrns, loadingIcosas }){
    const [displayedHEXValue, setDisplayedHEXValue] = useState(0);
    const [displayedHDRNValue, setDisplayedHDRNValue] = useState(0);
    const [displayedICSAValue, setDisplayedICSAValue] = useState(0);
    const [displayedINCValue, setDisplayedINCValue] = useState(0);
    const [lastINCValue, setLastINCValue] = useState(0);
    const [lastLastINCValue, setLastLastINCValue] = useState(0);

    
    //
    // BEGIN ICSA/MINER ONCHANGE
    //
    useEffect(() => {

        let totalHexMined = 0;
        let totalHexPrincipleValue = 0;
        let totalHexCombinedValue = 0;

        let totalHdrnPrinciple = 0;
        let totalHdrnMintable = 0;

        let totalIcsa = 0;
        let totalIcsaHdrnYield = 0;

        let totalIncYield = 0;
        

        if(items && Array.isArray(items)){
            if(items.length > 0 && items[0].results) {
                items.forEach((item)=>{
                    totalHexPrincipleValue += convertHeartsToHex(parseFloat(item.stakeHearts));
                    totalHexMined += item.results.hexMined;  
                    totalHdrnMintable += item.results.hdrnMintable;
                })
            }
        }

        
      

        
        if(icosas !== null){
            icosas.forEach((item)=>{
                totalIcsa += item.icsaStakedAmount + item.icsaStakeIcsaPayout;
                totalHdrnMintable += item.icsaStakeHdrnPayout;
                totalIcsaHdrnYield += item.icsaStakeHdrnPayout;
                
            })
        }

        if(hdrns !== null){
            hdrns.forEach((item)=>{
                totalIcsa += parseFloat(item.hdrnStakeIcsaPayout);
                totalHdrnPrinciple += parseFloat(item.hdrnStakedAmount);
            })
        }


        totalHexCombinedValue = totalHexMined + totalHexPrincipleValue;
      
        // HEX ANIMATION
        const hexStartValue = displayedHEXValue;
        const hexEndValue = totalHexCombinedValue;
        let hexCurrentFrame = 0;
        const hexTotalFrames = 60;
        const hexFrameInterval = 1000 / hexTotalFrames;
      
        const updateHexDisplayedValue = () => {
          const progress = hexCurrentFrame / hexTotalFrames;
          const easedProgress = 1 - Math.pow(1 - progress, 4); // Slow-down effect
          const newValue = hexStartValue + (hexEndValue - hexStartValue) * easedProgress;
          setDisplayedHEXValue(newValue);
          hexCurrentFrame++;
          if (hexCurrentFrame <= hexTotalFrames) {
            setTimeout(updateHexDisplayedValue, hexFrameInterval);
          }
        };
      
        if (hexStartValue !== hexEndValue) {
          setTimeout(updateHexDisplayedValue, hexFrameInterval);
        }
      
        // HDRN Animation
        const hdrnStartValue = displayedHDRNValue;
        const hdrnEndValue = totalHdrnMintable + totalHdrnYield + totalHdrnPrinciple;
        let hdrnCurrentFrame = 0;
        const hdrnTotalFrames = 60;
        const hdrnFrameInterval = 1000 / hdrnTotalFrames;
      
        const updateHdrnDisplayedValue = () => {
          const progress = hdrnCurrentFrame / hdrnTotalFrames;
          const easedProgress = 1 - Math.pow(1 - progress, 4); // Slow-down effect
          const newValue = hdrnStartValue + (hdrnEndValue - hdrnStartValue) * easedProgress;
          setDisplayedHDRNValue(newValue);
          hdrnCurrentFrame++;
          if (hdrnCurrentFrame <= hdrnTotalFrames) {
            setTimeout(updateHdrnDisplayedValue, hdrnFrameInterval);
          }
        };
      
        if (hdrnStartValue !== hdrnEndValue) {
          setTimeout(updateHdrnDisplayedValue, hdrnFrameInterval);
        }


        // ICSA Animation
        const icsaStartValue = displayedICSAValue;
        const icsaEndValue = totalIcsa; // fix this
        let icsaCurrentFrame = 0;
        const icsaTotalFrames = 60;
        const icsaFrameInterval = 1000 / icsaTotalFrames;
      
        const updateIcsaDisplayedValue = () => {
          const progress = icsaCurrentFrame / icsaTotalFrames;
          const easedProgress = 1 - Math.pow(1 - progress, 4); // Slow-down effect
          const newValue = icsaStartValue + (icsaEndValue - icsaStartValue) * easedProgress;
          setDisplayedICSAValue(newValue);
          icsaCurrentFrame++;
          if (icsaCurrentFrame <= icsaTotalFrames) {
            setTimeout(updateIcsaDisplayedValue, icsaFrameInterval);
          }
        };
      
        if (icsaStartValue !== icsaEndValue) {
          setTimeout(updateIcsaDisplayedValue, icsaFrameInterval);
        }


        // INC Animation
        const incStartValue = displayedINCValue;
        const incEndValue = incAmount; 
        let incCurrentFrame = 0;
        const incTotalFrames = 60;
        const incFrameInterval = 1000 / incTotalFrames;

                
        const updateINCDisplayedValue = () => {
            if(incCurrentFrame === 0) {
                if(lastINCValue !== 0) {
                    setLastLastINCValue(lastINCValue)
                }
                setLastINCValue(displayedINCValue)
            }
            const progress = incCurrentFrame / incTotalFrames;
            const easedProgress = 1 - Math.pow(1 - progress, 4); // Slow-down effect
            const newValue = incStartValue + (incEndValue - incStartValue) * easedProgress;
            setDisplayedINCValue(newValue);
            incCurrentFrame++;
            if (incCurrentFrame <= incTotalFrames) {
                setTimeout(updateINCDisplayedValue, incFrameInterval);
            }
        };
      
        if (incStartValue !== incEndValue) {
          setTimeout(updateINCDisplayedValue, incFrameInterval);
        }
      
    }, [items, icosas, incAmount]);
    //
    // END ICSA/MINER ONCHANGE
    //


    let totalIcosaPrinciple = 0;
    let totalIcosaYield = 0;
    let totalHdrnPrincipleInIcsa = 0;

    let totalHdrnPrinciple = 0;
    let totalHdrnYield = 0;
    let totalIcsaHdrnYield = 0;


    let totalpIcosaPrinciple = 0;
    let totalpIcosaYield = 0;
    let totalpHdrnPrincipleInIcsa = 0;

    let totalpHdrnPrinciple = 0;
    let totalpHdrnYield = 0;
    let totalpIcsaHdrnYield = 0;


    let avgDaysIcsaStakeServed = 0;
    let daysAdded = 0;

    if(icosas && icosas.length > 0) {
        icosas.filter(f=>f.chainId === "1").forEach((item)=>{
            totalIcosaPrinciple += item.icsaStakedAmount;
            totalIcosaYield += item.icsaStakeIcsaPayout;
            totalIcsaHdrnYield += item.icsaStakeHdrnPayout;
            avgDaysIcsaStakeServed += item.currentDay - item.icsaStakeStartDay;
            daysAdded++;
        })
        //
        //
        // ADD Pulsechain Here
        //
        //
    }
    if(hdrns !== null){
        hdrns.filter(f=>f.chainId === "1").forEach((item)=>{
            totalIcosaYield += parseFloat(item.hdrnStakeIcsaPayout);
            totalHdrnPrinciple += parseFloat(item.hdrnStakedAmount);

            avgDaysIcsaStakeServed += item.currentDay - item.hdrnStakeStartDay;
            daysAdded++;
        })
        //
        //
        // ADD Pulsechain Here
        //
        //
    }

    let totalIcosaCombinedValue = totalIcosaPrinciple + totalIcosaYield;
    let totalpIcosaCombinedValue = totalpIcosaPrinciple + totalpIcosaYield;


    let totalHexMined = 0;
    let totalHexPrincipleValue = 0;
    let totalHexCombinedValue = 0;
    let totalHdrnMintable = 0;
    let totalHexSharesRaw= 0;

    let totalpHexMined = 0;
    let totalpHexPrincipleValue = 0;
    let totalpHexCombinedValue = 0;
    let totalpHdrnMintable = 0;
    let totalpHexSharesRaw= 0;

    if(items && Array.isArray(items)){
        if(items.length > 0 && items[0].results) {
            items.filter(f=>f.chainName==="Ethereum Mainnet").forEach((item)=>{
                totalHexPrincipleValue += convertHeartsToHex(parseFloat(item.stakeHearts));
                totalHexMined += item.results.hexMined;  
                totalHdrnMintable += item.results.hdrnMintable;
                totalHdrnYield += item.results.hdrnMintable;
                totalHexSharesRaw += parseFloat(item.stakeShares);
            })
        }
        if(items.length > 0 && items[0].results) {
            items.filter(f=>f.chainName==="PulseChain Mainnet").forEach((item)=>{
                totalpHexPrincipleValue += convertHeartsToHex(parseFloat(item.stakeHearts));
                totalpHexMined += item.results.hexMined;  
                totalpHdrnMintable += item.results.hdrnMintable;
                totalpHdrnYield += item.results.hdrnMintable;
                totalHexSharesRaw += parseFloat(item.stakeShares);
            })
        }
    }
    let totalHexShares = totalHexSharesRaw / 1000000000000;
    let totalpHexShares = totalpHexSharesRaw / 1000000000000;



    let totalINCCombinedValue = displayedINCValue;
    let avgINCperHour = (((displayedINCValue - lastINCValue) + (lastINCValue - lastLastINCValue)) / 2).toFixed(3)
    avgINCperHour = (avgINCperHour * 4 * 60).toFixed(2);
    let avgINCperDay = (avgINCperHour * 24).toFixed(2);

    if (lastLastINCValue === 0) {
        avgINCperHour = 0;
        avgINCperDay = 0;
    } 

    let totalINCperHour = avgINCperHour ? parseFloat(avgINCperHour) : 0;
    let totalINCperDay = avgINCperDay ? parseFloat(avgINCperDay) : 0;
    
    // if(avgINCperHour > 9999) {
    //     avgINCperHour = nFormatter(avgINCperHour, 2)
    // }
    // if(avgINCperDay > 9999) {
    //     avgINCperDay = nFormatter(avgINCperDay, 2)
    // }

    let displayTotalHexShares = totalHexShares + totalpHexShares;
    let ethShareRate = 0;
    let plsShareRate = 0;
    if(shareRate != null){
        ethShareRate = totalHexShares * shareRate.eth;
        plsShareRate = totalpHexShares * shareRate.pls;
        //displayTotalHexShares = ethShareRate + plsShareRate;
    }

    if(quotes && quotes.length > 0 && selectedCurrency !== null) {
        let hdrnId = `HDRN_${selectedCurrency.id}`;
        let hexId = `HEX_${selectedCurrency.id}`;
        let icsaId = `ICSA_${selectedCurrency.id}`;
        let phdrnId = `pHDRN_${selectedCurrency.id}`;
        let phexId = `pHEX_${selectedCurrency.id}`;
        let picsaId = `pICSA_${selectedCurrency.id}`;
        let incId = `pINC_${selectedCurrency.id}`;
        quotes.forEach((q) =>{
            if(q.id === "HDRN" && q[hdrnId]){
                totalHdrnMintable *= q[hdrnId];
                totalIcsaHdrnYield *= q[hdrnId];
                totalHdrnYield *= q[hdrnId];
                totalHdrnPrinciple *= q[hdrnId];
            }
            if(q.id === "HEX" && q[hexId]){
                totalHexMined *= q[hexId];
                totalHexPrincipleValue *= q[hexId];
                totalHexShares *= q[hexId];
                if(ethShareRate !== 0) {
                    ethShareRate *= q[hexId];
                }
            }
            if(q.id === "ICSA" && q[icsaId]){
                totalIcosaPrinciple *= q[icsaId];
                totalIcosaYield *= q[icsaId];
                totalIcosaCombinedValue *= q[icsaId];
            }
            if(q.id === "pHDRN" && q[phdrnId]){
                totalpHdrnMintable *= q[phdrnId];
                totalpIcsaHdrnYield *= q[phdrnId];
                totalpHdrnYield *= q[phdrnId];
                totalpHdrnPrinciple *= q[phdrnId];
            }
            if(q.id === "pHEX" && q[phexId]){
                totalpHexMined *= q[phexId];
                totalpHexPrincipleValue *= q[phexId];
                totalpHexShares *= q[phexId];
                if(plsShareRate !== 0) {
                    plsShareRate *= q[phexId];
                }
            }
            if(q.id === "pICSA" && q[picsaId]){
                totalpIcosaPrinciple *= q[picsaId];
                totalpIcosaYield *= q[picsaId];
                totalpIcosaCombinedValue *= q[picsaId];
            }
            if(q.id === "pINC" && q[incId]) {
                totalINCperHour *= q[incId];
                totalINCperDay *= q[incId];
                totalINCCombinedValue *= q[incId];
            }
            // calculate separately
            if(q["HDRN_ICSA"]) {
                totalHdrnPrincipleInIcsa = totalHdrnPrinciple * q["HDRN_ICSA"];
            }
            if(q["pHDRN_pICSA"]) {
                totalpHdrnPrincipleInIcsa = totalpHdrnPrinciple * q["pHDRN_pICSA"];
            }
        })
    }
    if(shareRate != null){
        displayTotalHexShares = ethShareRate + plsShareRate;
    }
    totalHexCombinedValue = totalpHexMined + totalpHexPrincipleValue + totalHexMined + totalHexPrincipleValue
    totalHdrnMintable = totalHdrnMintable + totalpHdrnMintable;
    totalHexMined = totalHexMined + totalpHexMined;
    totalIcosaCombinedValue = totalIcosaCombinedValue + totalpIcosaCombinedValue;
    //
    // END
    //
    



    

    let displayTotalIcosaPrinciple = nFormatter(totalIcosaPrinciple + totalpIcosaPrinciple,2);
    let displayTotalIcosaYield = nFormatter(totalIcosaYield + totalpIcosaYield, 2);
    let displayTotalIcosaCombined = nFormatter(totalIcosaCombinedValue, 2);
    let displayTotalIcosaHdrnYield = nFormatter(totalIcsaHdrnYield + totalpIcsaHdrnYield, 2);

    let displayTotalIncValue = totalINCCombinedValue;
    let displayTotalIncPerHour = totalINCperHour;
    let displayTotalIncPerDay = totalINCperDay;
    try{
        displayTotalIncValue = totalINCCombinedValue.toFixed(3);
        displayTotalIncPerHour = totalINCperHour.toFixed(2);
        displayTotalIncPerDay = totalINCperDay.toFixed(2);
    }
    catch {
        displayTotalIncPerHour = 0;
        displayTotalIncPerDay = 0;
    }


    let displayIcosaYieldAPY = 0;
    if(avgDaysIcsaStakeServed && avgDaysIcsaStakeServed > 0) {
        avgDaysIcsaStakeServed = avgDaysIcsaStakeServed / daysAdded;
        displayIcosaYieldAPY = (totalIcosaYield / (totalHdrnPrincipleInIcsa + totalIcosaPrinciple)) * (365 / avgDaysIcsaStakeServed) * 100;
        if(typeof displayIcosaYieldAPY === "number") { displayIcosaYieldAPY = displayIcosaYieldAPY.toFixed(2) }

    }
    
    let displayTotalHdrnPrinciple = nFormatter(totalHdrnPrinciple, 2);
    let displayTotalHdrnMintable = nFormatter(totalHdrnMintable, 2);
    let displayTotalHdrnYield = nFormatter(totalHdrnYield, 2);
    let displayTotalHdrn = nFormatter(totalIcsaHdrnYield + totalHdrnPrinciple + totalHdrnYield, 2);

    let displayTotalHexMined = nFormatter(totalHexMined, 2);

    let displayTotalHexPrinciple = nFormatter(totalHexPrincipleValue + totalpHexPrincipleValue, 2);
    let displayTotalHexCombined = nFormatter(totalHexCombinedValue,2);
    

    
    
    displayTotalHexShares = nFormatter(displayTotalHexShares, 2);

    if(selectedCurrency !== null) {
        if(selectedCurrency.id === "USDC") {
            displayTotalIcosaPrinciple = "$" + displayTotalIcosaPrinciple;
            displayTotalIcosaYield = "$" + displayTotalIcosaYield;
            displayTotalIcosaCombined = "$" + displayTotalIcosaCombined;

            displayTotalIcosaHdrnYield  = "$" + displayTotalIcosaHdrnYield;
            displayTotalHdrnYield = "$" + displayTotalHdrnYield;
            displayTotalHdrnMintable = "$" + displayTotalHdrnMintable;
            displayTotalHdrnPrinciple = "$" + displayTotalHdrnPrinciple;
            displayTotalHdrn = "$" + displayTotalHdrn;

            displayTotalHexMined = "$" + displayTotalHexMined;
            displayTotalHexPrinciple= "$" + displayTotalHexPrinciple;
            displayTotalHexCombined= "$" + displayTotalHexCombined;
            displayTotalHexShares = "$" + displayTotalHexShares;

            displayTotalIncValue = "$" + nFormatter(parseFloat(displayTotalIncValue).toFixed(2), 2);
            displayTotalIncPerHour = "$" + nFormatter(parseFloat(displayTotalIncPerHour).toFixed(2), 2);
            displayTotalIncPerDay = "$" + nFormatter(parseFloat(displayTotalIncPerDay).toFixed(2), 2);
        }
        else {
            displayTotalIcosaPrinciple += " " + selectedCurrency.symbol;
            displayTotalIcosaYield += " " + selectedCurrency.symbol;
            displayTotalIcosaCombined += " " + selectedCurrency.symbol;
            
            displayTotalIcosaHdrnYield  += " " + selectedCurrency.symbol;
            displayTotalHdrnYield += " " + selectedCurrency.symbol;
            displayTotalHdrnMintable += " " + selectedCurrency.symbol;
            displayTotalHdrnPrinciple += " " + selectedCurrency.symbol;
            displayTotalHdrn += " " + selectedCurrency.symbol;

            displayTotalHexMined += " " + selectedCurrency.symbol;
            displayTotalHexPrinciple += " " + selectedCurrency.symbol;
            displayTotalHexCombined += " " + selectedCurrency.symbol;
            displayTotalHexShares += " " + selectedCurrency.symbol;

            displayTotalIncValue = nFormatter(displayTotalIncValue,2) + " " + selectedCurrency.symbol;
            displayTotalIncPerHour = nFormatter(displayTotalIncPerHour,2) + " " + selectedCurrency.symbol;
            displayTotalIncPerDay = nFormatter(displayTotalIncPerDay,2) + " " + selectedCurrency.symbol;
        }
    }
    else {
        displayTotalHexShares += " HEX"
    }





    return(
        <>
            <div className="card">
                <div className="card-body">
                    <div className="t-ac" style={{fontSize: "16px"}}>
                        Mining Progress
                    </div>
                    <div className="mining-status-disclaimer">
                        <small>(Principle + Yield)</small>
                    </div>

                    <div className="card-jumbo">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div>HEX</div>
                                    <div className="card-jumbo-content">
                                    {(loading || loadingIcosas) && <div className="jumbo-loader"><img src={LoadingIcon}/></div>}
                                        <div>
                                            {displayTotalHexCombined}
                                        </div>
                                        <div className="sub">
                                            <small>({displayTotalHexPrinciple} + {displayTotalHexMined})</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div>HDRN</div>
                                    <div className="card-jumbo-content">
                                    {(loading || loadingIcosas) && <div className="jumbo-loader"><img src={LoadingIcon}/></div>}
                                        <div>
                                            {displayTotalHdrn}
                                        </div>
                                        <div className="sub">
                                            <small>({displayTotalHdrnPrinciple} + {displayTotalIcosaHdrnYield} + {displayTotalHdrnYield})</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div>ICSA</div>
                                    <div className="card-jumbo-content">
                                        {(loadingIcosas) && <div className="jumbo-loader"><img src={LoadingIcon}/></div>}
                                            <div>
                                                {displayTotalIcosaCombined}
                                            </div>
                                            <div className="sub">
                                                <small>({displayTotalIcosaPrinciple} + {displayTotalIcosaYield})</small>
                                            </div> 
                                    </div>
                                </div>
                                <div className="col">
                                    <div>INC</div>
                                    <div className="card-jumbo-content">
                                        <div>
                                            {displayTotalIncValue}
                                        </div>
                                        <div className="sub">
                                            <small>({displayTotalIncPerHour}/hr ~ {displayTotalIncPerDay}/d)</small>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col small-header">
                            <div>HEX Shares</div>
                            <div>{typeof totalHexShares === "number" ? nFormatter(totalHexSharesRaw,2) : "-"}</div>
                        </div>
                        <div className="col small-header">
                            <Tooltip customClass="tooltip-shareValue" text={<ShareValueTooltip/>}>
                                <div>Share Value</div>
                                <div>{typeof totalHexShares === "number" ? displayTotalHexShares : "-"}</div>
                            </Tooltip>
                        </div>
                        <div className="col small-header">
                            <div>% Staked</div>
                            <div>TBD</div>
                        </div>
                        <div className="col small-header">
                            <Tooltip customClass="tooltip-icsaApy" text={<IcosaTooltip/>}>
                                <div>ICSA APY</div>
                                <div>{displayIcosaYieldAPY + "%"}</div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MiningStatusCard;