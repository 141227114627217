import { addOrUpdateWalletToStorage, removeWalletFromStorage } from "Components/Functional/DataStorage/DataManager";
import TextInputField from "Components/Functional/Input/TextInputField";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesRectangle } from "@fortawesome/free-regular-svg-icons";

const RenameWallets = ({items, handleUpdateItems}) => {
  

    const handleOnSubmit = (value, item) => {

        if(items.find((i)=> i.name === value)) {
            // Notify User That Name Already Exists?
        }
        else {
            let newItems = [...items];
            newItems.forEach((i)=>{
                if(i.address.toUpperCase() === item.address.toUpperCase()){
                    i.name = value;
                }
            });
            handleUpdateItems(newItems);
            
            let walletToSave = item;
            walletToSave.name = value;
            addOrUpdateWalletToStorage(walletToSave);
        }
    }

    const handleOnRemove = (item) => {
        let newItems = [];
        items.forEach((i)=>{
            if(i.address.toUpperCase() !== item.address.toUpperCase()){
                newItems.push(i);
            }
        });
        debugger;
        handleUpdateItems(newItems);
        removeWalletFromStorage(item);
    }


    return (
        <div className="drag-and-drop-container rename-watchlist">
            <div className="drag-and-drop-area animate-items">
                {items.map((item, index)=> {

                    return (
                        <div key={index} className={`drag-and-drop-item`}>
                            {item.name} {"=>"}
                            <TextInputField
                                maxLength={20}
                                minLength={1}
                                placeholder="Enter new alias"
                                handleOnSubmit={handleOnSubmit}
                                reference={item}
                            />
                            <div className="remove-wallet-button" onClick={()=>handleOnRemove(item)}>
                                <FontAwesomeIcon icon={faTimesRectangle}/>
                            </div>
                        </div>

                    )
                }
                )}
            </div>
        </div>
    );
};

export default RenameWallets;