import React from "react";
//import '../../../../Styling/SiteWide/Themes/default.css';

import LeftNavigationBar from "../../../Layout/LeftNavigationBar/LeftNavigationBar";
import SiteHeader from "../../../Layout/SiteHeader";
import SiteFooter from "../../../Layout/SiteFooter";
import MainContent from "Components/Layout/MainContent/MainContent";
import walletService from "../../../../Services/walletService";
import dataService from "../../../../Services/dataService";
import quoteService from "../../../../Services/quoteService";
import { addWalletToStorage, removeWalletFromStorage, getWalletsFromStorage, saveBalancesToStorage, getBalancesFromStorage, getMaximusDaoFromStorage, saveMaximusDaoToStorage, getUpdateStatus, setUpdateStatus } from "../../../Functional/DataStorage/DataManager";
import { getTokensFromStorage } from "../../../Functional/DataStorage/DataManager";
import { contractBatchBalanceOf, contractGetInc, contractGetShareRate, getHexMined } from "Components/Functional/Web3/ContractInteraction";
import { getAddressShortName, contractBalanceOf, balanceOfEth } from "Components/Functional/Web3/ContractInteraction";
import { maximusDaoTokens } from "../../../../Globals";
import MaintenanceModal from "Components/Functional/Modal/MaintenanceModal";
import TokenSettings from "Components/Layout/LeftNavigationBar/TokenSettings";

/*
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";


import MiningStatusCard from "./MiningStatusCard";
*/

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingWalletMiners: false,
      loadingIcosas: false,
      loadingTickers: true,
      loadingBalances: true,
      priceUpdated: new Date(),

      balanceInit: false,
      balanceLastRefreshed: null,
      incAmount: 0,
      tokens:[
        // Default values
        // { id: 1, name: "HEX", address: "0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39" },
        // { id: 2, name: "HDRN", address: "0x3819f64f282bf135d62168C1e513280dAF905e06"},
        // { id: 3, name: "ICSA", address: "0xfc4913214444aF5c715cc9F7b52655e788A569ed"}
      ],
      wallets: [],
      balances: null,
      miners: [],
      quotes: [],
      hex: {
        dailyFull: [],
        shareRate: null
      },
      icosa: [],
      hdrn: [],
      maximusDao: [],

      selectedWallet: null,
      selectedToken: null,
      selectedCurrency: null,
      selectedChain: "1"
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.selectedWallet !== this.state.selectedWallet) {
      this.getUpdatedInc()
      
    }

    // Update wallets if there are any new wallets
    if (//this.state.wallets.length > 0 && 
      prevState.wallets.length !== this.state.wallets.length) {
        this.getUpdatedInc();
        this.setState({loadingWalletMiners: true});
        this.updateMiners(prevState);
    }
    if (this.state.tokens.length > 0
      && prevState.tokens.length !== this.state.tokens.length) {
        this.getCurrentTickers(this.state.tokens);
      }

    // collect balances using ContractInteraction.balanceOf function here;
    // check wallets -> tickers -> balances? then update

    if(
      (!prevProps.provider && this.props.provider && this.props.pulsechainProvider)
      || (!prevProps.pulsechainProvider && this.props.provider && this.props.pulsechainProvider)      
      ) {

      

      contractGetShareRate(this.props.provider).then(r=>{
        let ethShareRate = r;
        contractGetShareRate(this.props.pulsechainProvider).then(r2=>{
          this.setState({hex: {...this.state.hex, shareRate: {
            eth: ethShareRate, pls: r2
          }}})
        });
      });
    }


    if(this.state.quotes && this.state.quotes.length > 0 &&
      this.state.wallets && this.state.wallets.length > 0 && 
      !this.state.loadingTickers && !this.state.balanceInit && 
      this.props.provider && this.props.pulsechainProvider) {

        this.getUpdatedInc();
        this.interval = setInterval(()=>{
          this.getUpdatedInc()
          // this.getCurrentTickers(this.state.tokens);
        }, 15000);

        this.updateBalancesLocal(this.state.wallets, this.state.tokens).
        then(r=>{
          this.setState({balanceInit: true, loadingBalances: false, balances: r})
        })
        .catch(e=>{
          this.setState({balanceInit: true, loadingBalances: false, balances: this.state.balances })
        })

        if(this.props.provider || this.props.pulsechainProvider) {

          this.updateBalancesLocal(this.state.wallets, this.state.tokens).
          then(r=>{
            this.setState({balanceInit: true, loadingBalances: false, balances: r})
          })
          .catch(e=>{
            this.setState({balanceInit: true, loadingBalances: false, balances: this.state.balances })
          })
        }
    }
  }

  componentDidMount=()=>{
    let updateStatus = getUpdateStatus();
      if(updateStatus !== '6') {
        console.log('Updated')
        this.props.onChangeTheme("4")
        // localStorage.removeItem('balances');
        // localStorage.removeItem('tokenList');

        setUpdateStatus('6');
        return;
      }

    const tokens = getTokensFromStorage();
    const wallets = getWalletsFromStorage();

    
    // PREP FOR MAXI DAO
    // const maxiDao = getMaximusDaoFromStorage();
    // if(maxiDao === null || maxiDao === undefined || maxiDao === []) {
    //   let maxiWallets = [];

    //   Object.keys(maximusDaoTokens).forEach(w=>maxiWallets.push(maximusDaoTokens[w].address))

    //   walletService.getWalletData(maxiWallets)
    //   .then(r=>{
    //     saveMaximusDaoToStorage(r);
    //     this.setState({maximusDao: r});
    //   })
    //   .catch(e=>{
    //     //
    //   });
    // }
    // else {
    //   this.setState({maximusDao: maxiDao});
    // }

    // Get Updated Daily HEX Stats
    if( this.state.hex.dailyFull.length < 1 ) {
      this.getDailyHexGlobal(wallets);
    }
    
    let loadingWalletMiners = wallets?.length > 0 ? true : false;
    this.setState({wallets: wallets, tokens: tokens, loadingIcosas: loadingWalletMiners, loadingWalletMiners: loadingWalletMiners, theme: "default"})
  }

  getUpdatedInc = () => {
    if(!this.props.pulsechainProvider ||  !this.state.wallets ||  this.state.wallets.length < 1)
      return;

    try{
      if(this.state.wallets.length === 0 && this.state.incAmount != 0) {
        this.setState({incAmount: 0})
      }
      else if ( this.state.wallets.length > 0 ){
        if(this.state.selectedWallet !== null){
          contractGetInc(this.props.pulsechainProvider, [this.state.selectedWallet]).then(r=>{
            this.setState({incAmount: r});
          })
        }
        else{
          contractGetInc(this.props.pulsechainProvider, this.state.wallets).then(r=>{
            this.setState({incAmount: r});
          })          
        }
      }
    }
    catch {
      // swallow this error
    }
  }

  getCurrentTickers = (tokens, attempts = 1) => {
    console.log("Updating Tickers")

    if(!Array.isArray(tokens)) return; 

     const symbols = tokens.filter(t=>t.platform === 'ETH').map(token => token.nomics_id);
     let payload = symbols.join(",");

     payload = payload.replace("MAXI", "__MAXI");

      quoteService.getCurrency(payload)
      .then((response) => {
        const pulseTokens = tokens.filter(t=>t.platform === 'PLS');

        quoteService.getPulseCurrency(pulseTokens).then(response2=>{
          let combined = []

          const pulseTokens = tokens.filter(t=>t.platform === 'PLS');

          let formattedResponse = response2.data.data.tokens;
          let filteredResponse = [];
          for(let i = 0; i < formattedResponse.length; i++){
            for(let k = 0; k < pulseTokens.length; k++) {
              if(pulseTokens[k].address.toUpperCase() === formattedResponse[i].id.toUpperCase()) {
                filteredResponse.push(formattedResponse[i])
                break;
              }
            }
          }

          // Add to quotes and parse here
          if(Array.isArray(response.data)) {
            response.data.forEach(d=>{
              d.platform = "ETH";
              combined.push(d);
            })
          }

          let plsPerDai = 0;
          for(let t = 0; t < filteredResponse.length; t++) {
            if(filteredResponse[t].symbol === "DAI") {
              plsPerDai = parseFloat(filteredResponse[t].derivedPLS);
            }
          }

          combined.push({
            platform: "PLS",
            code: "PLS",
            name: "PLS",
            nomics_id: "PLS",
            rate: 1 / plsPerDai,
          })
          
          if(Array.isArray(filteredResponse)) {
            filteredResponse.forEach(d=>{
              d.platform = "PLS";
              d.code = "p" + d.symbol;
              d.name = d.symbol;
              d.nomics_id = d.symbol;
              d.rate = parseFloat(d.derivedPLS) / plsPerDai;
              d.totalSupply = parseFloat(d.totalSupply);
              combined.push(d);
            })
          }
          //let parsed = quoteService.parseCurrencies(response.data);
          let parsed = quoteService.parseCurrencies(combined);
          
          this.setState({quotes: parsed, loadingTickers: false, priceUpdated: new Date() })
        })
        .catch(e=>{
          debugger;
        })
        
        
      })
      .catch((e)=>{
        if (attempts < 50) {
          console.error(`Error getting currency quotes from Nomics. Retrying... (Attempt ${attempts})`);
          setTimeout(() => {
            this.getCurrentTickers(tokens, attempts + 1);
          }, 3000);
        } else {
          console.error("Error getting currency quotes from Nomics. Aborting.");
          this.setState({ quotes: [], loadingTickers: false });
        }
      })
  }

  getDailyHexGlobal =(wallets) =>{
    dataService.getDailyFull().then(r=>{
      //let dailyFull = JSON.parse(r.data.item);
      let dailyFull = r.data;
      let pulseDailyFull = r.data.filter(f=>f.chainId === "369");
      let ethDailyFull = r.data.filter(f=>f.chainId === "1");

      // Add global to miners if they have already been pulled down
      let newMiners = [];
      if(this.state.miners?.length > 0) {
        this.state.miners.forEach(item=>{
          
          let updatedMiner;
          if(item.chainId === "1") {
            updatedMiner = getHexMined(item, ethDailyFull, this.state.quotes);
          }
          if(item.chainId === "369") {
            updatedMiner = getHexMined(item, pulseDailyFull, this.state.quotes);
          }

          newMiners.push(updatedMiner);
        })
      }


      this.setState({
        //... this.state,
        miners: newMiners,
        //loadingWalletMiners: this.state.miners.length > 0 ? false : this.state.loadingWalletMiners,
        hex: { ... this.state.hex
          , dailyFull: dailyFull
          , currentDay: dailyFull[0].currentDay
        }  
      })
    }).catch(e=>{
      //debugger;
    })
  }

  updateMiners = (prevState = null) => {
    let newState = {
      wallets: this.state.wallets,
      miners: this.state.miners,
      icosa: this.state.icosa,
      hdrn: this.state.hdrn,
      tokens: this.state.tokens,
      loadingWalletMiners: false,
      balances: this.state.balances
    }

    prevState = prevState === null ? newState : prevState;

    const newWallets = this.state.wallets.filter(wallet => !prevState.wallets.includes(wallet));

    if (newWallets?.length > 0) {

      // check if balances need to be updated
      let walletsToRetrieveBalance = [];
      newWallets.forEach(w=>{
        const walletExists = newState.balances && newState.balances.some(obj => obj.wallet.toLowerCase() === w.address.toLowerCase());
        if (!walletExists) {
          walletsToRetrieveBalance.push(w);
        }
      })

      if(walletsToRetrieveBalance.length > 0) {
        this.updateBalances(walletsToRetrieveBalance, this.state.tokens)
        .then(newBal=>{
          if (Array.isArray(this.state.balances)) {
            // this.state.balances.forEach(b => {
            //   // Check if wallet value already exists in newBal array
            //   const walletExists = newBal.some(obj => obj.wallet.toLowerCase() === b.wallet.toLowerCase());
            //   if (!walletExists) {
            //     newBal.push(b);
            //   }
            // });

            const existingBalances = newBal.reduce((map, obj) => {
              map[obj.wallet.toLowerCase()] = obj;
              return map;
            }, {});
      
            // Iterate over the balances and update if necessary
            this.state.balances.forEach(b => {
              const wallet = b.wallet.toLowerCase();
              if (existingBalances[wallet]) {
                // Balance exists, update the balance
                existingBalances[wallet].balance = b.balance;
              } else {
                // Balance doesn't exist, add it to the newBal array
                newBal.push(b);
              }
            });

            saveBalancesToStorage(newBal);
            this.setState({ balances: newBal, loadingBalances: false });
          }
        })
        this.setState({loadingBalances: true})
      }



      // Get new wallet stakes
      const walletAddresses = newWallets.map(wallet => wallet.address);
      const payload = { wallets: walletAddresses };

      walletService.getStakes(payload)
        .then(response => {
          let stillLoading = false;
          if(!this.state.hex.dailyFull) {
            stillLoading = true;
          }

          if (!Array.isArray(response.data)) {
            this.setState({loadingWalletMiners: stillLoading, selectedWallet: null, selectedToken: null});
          }
          else if (Array.isArray(response.data)) {
            let responseMiners = response.data;
            
            let newMiners = [...newState.miners];
            if(responseMiners?.length > 0) {

              let pulseDailyFull = this.state.hex.dailyFull.filter(f=>f.chainId === "369");
              let ethDailyFull = this.state.hex.dailyFull.filter(f=>f.chainId === "1");
                            
              responseMiners.forEach(item=>{
                let updatedMiner;

                if(item.chainId === "1") {
                  updatedMiner = getHexMined(item, ethDailyFull, this.state.quotes);
                }
                else if (item.chainId === "369") {
                  updatedMiner = getHexMined(item, pulseDailyFull, this.state.quotes);
                }


                updatedMiner.name = getAddressShortName(updatedMiner.stakerAddress);

                for(let i = 0; i < newState.wallets.length; i++) {
                  let w = newState.wallets[i];
                  if(w.address.toUpperCase() === updatedMiner.stakerAddress.toUpperCase() || w.address.toUpperCase() === updatedMiner.owningAddress?.toUpperCase()){
                    updatedMiner.name = w.name;
                    break;
                  }
                }

                newMiners.unshift(updatedMiner);
              })
            }

            // FILTER OFF INACTIVES FOR NOW
            // HANDLE ENDED STAKES HERE AT A LATER DATE
            newMiners = newMiners.filter((item)=> item.stakeEndTimestamp === null || item.stakeEndTimestamp === 0)

            if(this.state.miners && this.state.miners.length < 1) {
              stillLoading = false;
            }
            this.setState({miners: newMiners, loadingWalletMiners: stillLoading, selectedWallet: null, selectedToken: null});
          }
        })
        .catch(error => {
          this.setState({miners: [], loadingWalletMiners: false});
        });

      //0xFb2D5059dE24C88E560d1D474186187C0Cfc761e
      //0xE691d00625733231f95974D06cb71a3feb42488e
      walletService.getWalletData(payload)
      .then(response=>{

        let newIcosas = [...newState.icosa];
        let newHdrns = [...newState.hdrn];

        if(response.data && response.data.length > 0) {

          response.data.forEach(s=>{
            if(s.icosaStakes && Array.isArray(s.icosaStakes)){
              s.icosaStakes.forEach(ic=>{
                newIcosas.push(ic);
              })
            }
            if(s.hdrnStakes && Array.isArray(s.hdrnStakes)){
              s.hdrnStakes.forEach(hc=>{
                newHdrns.push(hc);
              })
            }
          })
        }

        this.setState({icosa: newIcosas, hdrn: newHdrns, loadingIcosas: false})
      })
      .catch(error=>{
        this.setState({icosa: [], hdrn: [], loadingIcosas: false})
      })
    }   

    
    // A wallet was removed
    else if (newWallets?.length < prevState.wallets?.length) {
      let newMiners = this.state.miners.filter((m)=>{
        return !newWallets.includes(m.address);
      })

      let newIcosas = this.state.icosa.filter((m)=>{
        let result = false;
        newState.wallets.forEach(w=>{
          if(w.address.toUpperCase() === m.stakerAddress.toUpperCase()) result = true;
        })
        return result;
      })
      let newHdrns = this.state.hdrn.filter((m)=>{
        let result = false;
        newState.wallets.forEach(w=>{
          if(w.address.toUpperCase() === m.stakerAddress.toUpperCase()) result = true;
        })
        return result;
      })
      // no new wallets were found
      this.setState({ miners: newMiners, hdrn: newHdrns, icosa: newIcosas, loadingWalletMiners: false, loadingIcosas: false});
    }
  }


  updateBalancesLocal = async (wallets, tokens) => {
    let balances = [];
    let balanceStore = getBalancesFromStorage();

    if(balanceStore != null && balanceStore != undefined) {
      let currentTime = Date.now();
      const secondsPassed = Math.floor((currentTime - balanceStore.refreshTime) / 1000);

      if( secondsPassed > 300 ) {
        this.setState({balanceInit: true, balanceLastRefreshed: Date.now() })

        balances = await this.updateBalances(wallets, tokens);
        saveBalancesToStorage(balances);
      }
      else {
        this.setState({balanceInit: true, balanceLastRefreshed: balanceStore.refreshTime })
        balances = balanceStore.balances;
      }
    }
    else {
      this.setState({balanceInit: true, balanceLastRefreshed: Date.now() })
      balances = await this.updateBalances(wallets, tokens);
      saveBalancesToStorage(balances);
    }
    return balances;
  }

  updateBalances = async (wallets, tokens) => {
    let balances = [];

    
    try {
      if(this.props.provider){
        let ethTokens = this.state.tokens.filter(f=>f.platform==="ETH");
        let ethBalances = await contractBatchBalanceOf(this.props.provider, ethTokens, wallets, "ETH")        
        ethBalances.forEach(bal=>balances.push(bal));

        try {
          for(let w = 0; w < wallets.length; w++) {
            let ethBalance = await balanceOfEth(this.props.provider, wallets[w].address)
            let balanceObj = {
              token: {nomics_id: "ETH", name: "ETH", address: "0x0", id: 0},
              wallet: wallets[w].address,
              balance: ethBalance,
              platform: "ETH"
            }
            balances.push(balanceObj);
          }
        }
        catch{
          // no eth?
        }
      }
    }
    catch{
      console.log("Unable to connect to Ethereum RPC")
    }

    try {
      if(this.props.pulsechainProvider){
        let plsTokens = this.state.tokens.filter(f=>f.platform==="PLS");
        let plsBalances = await contractBatchBalanceOf(this.props.pulsechainProvider, plsTokens, wallets, "PLS")        
        plsBalances.forEach(bal=>balances.push(bal));
        
        try {
          for(let w = 0; w < wallets.length; w++) {
            let plsBalance = await balanceOfEth(this.props.pulsechainProvider, wallets[w].address)
            let balanceObj = {
              token: {nomics_id: "PLS", name: "PLS", address: "0x0", id: 0},
              wallet: wallets[w].address,
              balance: plsBalance,
              platform: "PLS"
            }
            balances.push(balanceObj);
          }  
        }
        catch {
          // no pls?
        }
      }
    }
    catch{
      console.log("Unable to connect to PulseChain RPC")
    }
    
    return balances;
  }




  // #region Update Balances - Not in Use Yet
  // updateBalances = (wallets) => {
    
  //   let balances = getBalancesFromStorage()
  //   let exists = false;

  //   if(balances !== null) {
  //     for (let i = 0; i < balances.balances.length; i++) {
  //       if (wallets.find((w)=>w.address === balances.balances[i].wallet)) {
  //         exists = true;
  //         break;
  //       }
  //     }
  //   }


  //   if(exists) {
  //     let nowTime = Date.now();
  //     let timeDiff = (nowTime - balances.refreshTime)
  //     if(timeDiff < 86400000) {
  //       this.setState({ balances })
  //       return;
  //     }
  //   }


  //   let tokens = this.state.tokens.map((t)=> t.address);
  //   let pWallets = wallets.map((w)=> w.address );

  //   let payload = {
  //     wallets: pWallets,
  //     tokens: tokens,
  //     chainId: this.state.selectedChain
  //   }

  //   walletService.getBalances(payload)
  //   .then((r)=>{
  //     let balances = r.data;
  //     balances = this.parseTokensToBalances(r.data, this.state.tokens)

  //     saveBalancesToStorage(balances);
  //     this.setState({ balances: { balances: balances, refreshTime: Date.now() } })
  //   }).catch((e)=>{
  //     //debugger;
  //   })
  // }

  parseTokensToBalances(balances, tokens){

    if(!tokens || !balances || !Array.isArray(balances)) {
      return balances
    }

    for (let i = 0; i < balances.length; i++) {
        const token = tokens.find((t) => t.address.toUpperCase() === balances[i].tokenAddress.toUpperCase());
        if (token) {
          balances[i].nomics_id = token.nomics_id;
        }
    }
    return balances
  }
  // #endregion

  handleUpdateTokens = (items) => {
    this.setState({ tokens: items })
  }

  handleUpdateWallets = (items) => {
    let newMiners = [];
 
    // Take each miner, and only show a miner if the wallet exists in the new list
    for(let i = 0; i < items.length; i++) {
      this.state.miners.forEach(m=>{ 
        let exists = m.stakerAddress.toUpperCase() === items[i].address.toUpperCase();
        let ownerExists = (m.owningAddress && m.owningAddress.toUpperCase() === items[i].address.toUpperCase());
        if(exists || ownerExists) {
          m.name = items[i].name;
          newMiners.push(m);
        }
      })
    }

    this.setState({ wallets: items, miners: newMiners })
  }

  handleSelectToken = (item) => {
    this.setState({ selectedToken: item })
  }

  handleSelectWallet = (item) => {
    this.setState({ selectedWallet: item})
  }
  
  handleSelectCurrency = (item) => {
    this.setState({ selectedCurrency: item })
  }

  render() {
    return (<div className="main-page">
              {/* <MaintenanceModal/> */}
              <SiteHeader 
                theme={this.props.theme} 
                onChangeTheme={this.props.onChangeTheme}
                quotes={this.state.quotes}
              />
              <div style={{display:"flex"}}>
                <LeftNavigationBar 
                  provider={this.props.provider} 
                  pulseProvider={this.props.pulsechainProvider}
                  tokens={this.state.tokens}
                  quotes={this.state.quotes}
                  wallets={this.state.wallets}
                  balances={this.state.balances}
                  handleUpdateTokens={this.handleUpdateTokens}
                  handleUpdateWallets={this.handleUpdateWallets}
                  handleSelectWallet={this.handleSelectWallet}
                  handleSelectToken={this.handleSelectToken}
                  handleSelectCurrency={this.handleSelectCurrency}
                  loadingTickers={this.state.loadingTickers}
                  loadingBalances={this.state.loadingBalances}
                  selectedWallet={this.state.selectedWallet}
                  selectedToken={this.state.selectedToken}
                  selectedCurrency={this.state.selectedCurrency}
                />
                <MainContent 
                  theme={this.props.theme}
                  miners={this.state.miners}
                  loading={this.state.loadingWalletMiners}
                  loadingBalances={this.state.loadingBalances}
                  quotes={this.state.quotes}
                  balances={this.state.balances}
                  incAmount={this.state.incAmount}
                  provider={this.props.provider}
                  daily={this.state.hex?.dailyFull ? this.state.hex.dailyFull[0] : null}
                  wallets={this.state.wallets}
                  icosas={this.state.icosa}
                  hdrns={this.state.hdrn}
                  shareRate={this.state.hex.shareRate}
                  loadingIcosas={this.state.loadingIcosas}
                  selectedWallet={this.state.selectedWallet}
                  selectedToken={this.state.selectedToken}
                  selectedCurrency={this.state.selectedCurrency}
                />
              </div>

              <SiteFooter/>

              
            </div>)
  }
}

export default MainPage;