import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import TokenWatchList from "./TokenWatchlist";
import WalletWatchList from "./WalletWatchlist";
import CurrencySwitch from "./CurrencySwitch";
import FilterNetworks from "./FilterNetworks";
import QuickLinks from "./QuickLinks";

class LeftNavigationBar extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      mobileMenuOpen: false
    };
  }

  handleMobileMenuToggle = () => {
    this.setState({mobileMenuOpen: !this.state.mobileMenuOpen})
  }

  handleChangeCurrencyView = (newSelectedCurrency) => {
    this.setState({mobileMenuOpen: false});
    this.props.handleSelectCurrency(newSelectedCurrency);
  }

  menuClassMobile = () => {
    if(this.state.mobileMenuOpen) return "open-menu"
    else return "close-menu"
  }

  render() {
    return (
        <div className={`left-nav-bar ${this.menuClassMobile()}`}>
          <div className="mobile-only menu-bars" onClick={this.handleMobileMenuToggle}>
            <FontAwesomeIcon icon={faBars}/>
          </div>
          {/* <FilterNetworks/> */}
          <WalletWatchList 
            mobileMenuOpen={this.state.mobileMenuOpen}
            provider={this.props.provider}
            items={this.props.wallets}
            balances={this.props.balances}
            tokens={this.props.tokens}
            quotes={this.props.quotes}
            handleUpdateItems={this.props.handleUpdateWallets}
            handleSelectItem={this.props.handleSelectWallet}
            selectedItem={this.props.selectedWallet}
            selectedCurrency={this.props.selectedCurrency}
            selectedToken={this.props.selectedToken}
            />          
          <CurrencySwitch
            quotes={this.props.quotes}
            handleSelectItem={this.handleChangeCurrencyView}
            // {this.props.handleSelectCurrency}
            selectedItem={this.props.selectedCurrency}
            />
          <TokenWatchList 
            provider={this.props.provider} 
            pulseProvider={this.props.pulseProvider}
            items={this.props.tokens} 
            quotes={this.props.quotes}
            loading={this.props.loadingTickers}
            handleUpdateItems={this.props.handleUpdateTokens}
            handleSelectItem={this.props.handleSelectToken}
            selectedItem={this.props.selectedToken}
            selectedCurrency={this.props.selectedCurrency}
            />
          <QuickLinks/>
        </div>
    )
  }
}

export default LeftNavigationBar;