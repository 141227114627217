import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import MiningStatusCard from "./MiningStatusCard";
import MiningListCard from "./MiningListCard";
import DailyYieldChart from "Components/Functional/Charts/DailyYieldChart";
import StakingLadderChart from "Components/Functional/Charts/StakingLadderChart";
import LiquidBalances from "./LiquidBalances";

class MainContent extends React.Component {
  constructor(props) {
    super(props);
  }

    filteredMiners = () =>{
      let selectedWallet = this.props.selectedWallet;
      let items = this.props.miners;

      let displayItems;

      

      //0x4a79fd624b897c3c6920ae30e77572233281ec91,0x00073d1f76DbebF9e694A67190fa8Ab60a161459
      if(selectedWallet !== null && selectedWallet !== undefined) {
          let filterAddress = selectedWallet.address.toUpperCase();
          displayItems = items.filter((item) => {
              return item.stakerAddress?.toUpperCase() === filterAddress || item.owningAddress?.toUpperCase() === filterAddress;
          });
      }
      else displayItems = items;

      return displayItems;
    }

    filteredIcosas = () =>{
      let selectedWallet = this.props.selectedWallet;
      let items = this.props.icosas;

      let displayItems;

      //0x4a79fd624b897c3c6920ae30e77572233281ec91,0x00073d1f76DbebF9e694A67190fa8Ab60a161459
      if(selectedWallet !== null && selectedWallet !== undefined) {
          let filterAddress = selectedWallet.address.toUpperCase();
          displayItems = items.filter((item) => {
              return item.stakerAddress?.toUpperCase() === filterAddress
          });
      }
      else displayItems = items;

      return displayItems.filter(f=>f.chainId === "1");
    }

    filteredHdrns = () =>{
      let selectedWallet = this.props.selectedWallet;
      let items = this.props.hdrns;

      let displayItems;

      //0x4a79fd624b897c3c6920ae30e77572233281ec91,0x00073d1f76DbebF9e694A67190fa8Ab60a161459
      if(selectedWallet !== null && selectedWallet !== undefined) {
          let filterAddress = selectedWallet.address.toUpperCase();
          displayItems = items.filter((item) => {
              return item.stakerAddress?.toUpperCase() === filterAddress
          });
      }
      else displayItems = items;

      return displayItems.filter(f=>f.chainId === "1");
    }


    filteredBalances = () =>{
      let selectedWallet = this.props.selectedWallet;
      let items = this.props.hdrns;


      let displayItems;

      //0x4a79fd624b897c3c6920ae30e77572233281ec91,0x00073d1f76DbebF9e694A67190fa8Ab60a161459
      if(selectedWallet !== null && selectedWallet !== undefined) {
          let filterAddress = selectedWallet.address.toUpperCase();
          if(!this.props.balances) {return []}
          displayItems = this.props.balances.filter((item) => {
            return item.wallet?.toUpperCase() === filterAddress
          });
      }
      else {
        if(Array.isArray(this.props.balances)) {
          displayItems = this.props.balances.filter(balance => {
            return this.props.wallets.some(wallet => wallet.address === balance.wallet);
          });
        }
        else{
          displayItems = items;
        }
      }

      return displayItems;
    }

    render(){
        return(
            <div className="main-content-container">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="main-page-header">
                        {/* <div className="fa-class"><FontAwesomeIcon icon={faCoins}/></div> */}
                        Dashboard
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <MiningStatusCard
                        items={this.filteredMiners()}
                        icosas={this.filteredIcosas()}
                        hdrns={this.filteredHdrns()}
                        loading={this.props.loading}
                        loadingIcosas={this.props.loadingIcosas}
                        quotes={this.props.quotes}
                        daily={this.props.daily}
                        selectedCurrency={this.props.selectedCurrency}
                        incAmount={this.props.incAmount}
                        shareRate={this.props.shareRate}
                        />
                      <div className="mining-status-disclaimer">
                        <small>* Note: Calculations are estimated based on recent blockchain data and are provided for informational purposes only, and thus may not be entirely precise</small>
                      </div>
                    </div>
                  </div>

                  <div className="spacer-t10"/>

                  <div className="row">
                    <div className="col-12">
                      <LiquidBalances
                        loading={this.props.loadingBalances}
                        balances={this.filteredBalances()}//{this.props.balances}
                        quotes={this.props.quotes}
                        daily={this.props.daily}
                        provider={this.props.provider}
                        selectedCurrency={this.props.selectedCurrency}
                        selectedWallet={this.props.selectedWallet}
                        />
                      <div className="mining-status-disclaimer">
                        <small>* Note: Calculations are estimated based on recent blockchain data and are provided for informational purposes only, and thus may not be entirely precise</small>
                      </div>
                    </div>
                  </div>

                  <div className="spacer-t40"/>

                  <div className="row">
                    <div className="col-12">
                      <MiningListCard 
                          items={this.filteredMiners()} 
                          wallets={this.props.wallets}
                          loading={this.props.loading}
                          selectedWallet={this.props.selectedWallet}
                          quotes={this.props.quotes}
                          selectedCurrency={this.props.selectedCurrency}/>
                    </div>
                  </div>
                  <div className="spacer-t40"/>


                  <div className="row">
                    <div className="col">
                      <div className="card chart-card">
                        <div className="card-body">
                          <DailyYieldChart miners={this.filteredMiners()} quotes={this.props.quotes} selectedCurrency={this.props.selectedCurrency}/>
                          <div className="t-ac">
                            <small>Click and drag over an area to zoom in</small>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="spacer-t40"/>

                <div className="row">
                  <div className="col">
                      <div className="card chart-card">
                        <div className="card-body">
                          <StakingLadderChart miners={this.filteredMiners()} quotes={this.props.quotes} selectedCurrency={this.props.selectedCurrency}/>
                          <div className="t-ac">
                            <small>Click and drag over an area to zoom in</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

            </div>
        )
    }
}

export default MainContent;