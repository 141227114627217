import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./Styling/styling.scss";
import PulseChainBG from "./Art/Images/HexBackdropAlpha.png"

import MainPage from "./Components/Pages/Public-Use/Main Page/MainPage"
import EthersWrapper from './Components/Functional/Web3/EthersWrapper';
import { getThemeFromStorage, setThemeToStorage } from "Components/Functional/DataStorage/DataManager";
import EthersInjector from "EthersInjector";

function App() {
  const [init, setInit] = useState(false);
  const [theme, setTheme] = useState();
  const [themeValue, setThemeValue] = useState("0");

  useEffect(() => {
    //const urlParams = new URLSearchParams(window.location.search);
    let themeId = getThemeFromStorage();
    if(!themeId) {
      setThemeToStorage("4")
      themeId = "4";
    }

    if(init && themeId !== themeValue) {
      updateTheme(themeId);
      window.location.reload()
    }
    else {
      updateTheme(themeId);
    }

    
    setThemeValue(themeId);
    setTheme(themeId);
  }, [theme]);

  const updateTheme=(theme)=>{
    if (theme === "1") {
      import('./Styling/SiteWide/Themes/purpleshock.css').then(()=>setInit(true))
    } else if (theme === "2") {
      import('./Styling/SiteWide/Themes/classic.css').then(()=>setInit(true))
    } else if (theme === "3") {
      //import('./Styling/SiteWide/Themes/pulsex.css')
      import('./Styling/SiteWide/Themes/myeyeshurt.css').then(()=>setInit(true))
    } else if (theme === "4") {
      import('./Styling/SiteWide/Themes/pulsechain.css').then(()=>setInit(true))
    } else {
      import('./Styling/SiteWide/Themes/default.css').then(()=>setInit(true))
    }
  }

  const onChangeTheme=(newTheme)=>{
    setThemeToStorage(newTheme);
    setTheme(newTheme);
  }

  const themeWrapping = () => {
    return "theme-" + theme
  }

  const themeStyling = () => {
    if (theme === "4") {
      return "background-image-theme4"
    } else {
      return "";
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className={`main-wrapper ${themeWrapping()}`}>
            <div className={themeStyling()} style={{backgroundImage: `url(${PulseChainBG})`}} />
            {init && <EthersInjector theme={theme} onChangeTheme={onChangeTheme}/>}
        </div>
      </header>
    </div>
  );
}

export default App;
