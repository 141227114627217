import React from "react";

import '@fortawesome/fontawesome-free/css/all.min.css';
import "./Styling/styling.scss";

import MainPage from "./Components/Pages/Public-Use/Main Page/MainPage"
import EthersWrapper from './Components/Functional/Web3/EthersWrapper';
import Auctions from "Components/Functional/Auctions";

function EthersInjector(props) {
  return (
      <EthersWrapper>
            <MainPage theme={props.theme} onChangeTheme={props.onChangeTheme}/>
            {/* <Auctions/> */}
      </EthersWrapper>
  );
}

export default EthersInjector;
