import React, { useState } from 'react';

function Tooltip(props) {
  const [show, setShow] = useState(false);

  const toggleTooltipOn = () => {
    setShow(true);
  };

  const toggleTooltipOff = () => {
    setShow(false);
  };

  return (
    <span className="tooltip-container">
      <span className={`tooltip-text ${show ? 'show' : ''} ${props.customClass ? props.customClass : ""}`}>{props.text}</span>
      <span className="tooltip-trigger" onMouseEnter={toggleTooltipOn} onMouseLeave={toggleTooltipOff}>
        {props.children}
      </span>
    </span>
  );
}

export default Tooltip;