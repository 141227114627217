import React, { useState, useEffect } from "react";
import DragAndDropItem from "./DragAndDropItem";
import DragAndDropItemWallet from "./DragAndDropItemWallet";
import DragAndDropItemDisabled from "./DragAndDropItemDisabled";

const DragAndDropArea = (props) => {
  // props route sets the route
  const [items, setItems] = useState([]);

  useEffect(() => {
    if(props.items === undefined)
      setItems([])
    else
      setItems(props.items);
  }, [props.items]);

  const [draggedItemIndex, setDraggedItemIndex] = useState(-1);
  const [hoveredIndex, setHoveredIndex] = useState(0);
  const [draggedItem, setDraggedItem] = useState(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleDragStart = (event, item) => {
    setDraggedItem(item);
    setDraggedItemIndex(items.findIndex(i => i.id === item.id));
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/plain", JSON.stringify(item));
    event.dataTransfer.setDragImage(event.target, 20, 20);

    for(let i = 0; i < items.length; i++){
      items[i].itemIndex = i;
    }

  };

  const handleDragOver = event => {
    event.preventDefault();
    setIsDraggingOver(true);
    // setDraggedItem(null);
    // setDraggedItemIndex(-1);
  };

  const handleDragEnd = event => {
    setIsDraggingOver(true);
    setDraggedItem(null);
    setDraggedItemIndex(-1);
  }

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };


  const handleDrop = (event, item) => {
    event.preventDefault();
    event.stopPropagation();

    const draggedItemIndex = items.findIndex(i => i.id === draggedItem.id);
    let itemIndex = items.findIndex(i => i.id === item.id);
    const newItems = [...items];

    if(draggedItemIndex === itemIndex) return;

    newItems.splice(draggedItemIndex, 1);
    console.log("ItemIndex " + itemIndex + ", DraggedIndex " + draggedItemIndex)

    if (item.id === -2) {
      // put at end of array
      newItems.push(draggedItem);
    } else if (item.id === -1) {
      // put at front of array
      newItems.unshift(draggedItem);
    } else{
      newItems.splice(itemIndex, 0, draggedItem);
    }

    for(let i = 0; i < newItems.length; i++){
      newItems[i].itemIndex = i;
    }

    setItems(newItems);
    setDraggedItem(null);
    setDraggedItemIndex(-1);

    if(props.handleUpdateItems !== undefined && props.handleUpdateItems !== null)
      props.handleUpdateItems(newItems);
  };

  const handleDragEnter = (event, item) => {
    if (draggedItem !== null) {
      setHoveredIndex(items.findIndex(i => i.id === item.id));
    }
  };

  const handleOnClick = (event, item) => {
    event.preventDefault();
    event.stopPropagation();

    if(props.handleOnClick !== undefined)
      props.handleOnClick(event, item);
  }



  return (
    <div className="drag-and-drop-container">
      <div className="drag-and-drop-area animate-items" 
        onDragOver={handleDragOver} 
        onDrop={e => handleDrop(e, { id: 0 })}
        >
        <DragAndDropItemDisabled id={-1}/>
        {items.map(item => {
            
            let ref3filtered = !props.reference3 ? null : props.reference3.filter((i)=>{
              if(i && i.wallet && typeof i.wallet === "string") {
                return i.wallet.toUpperCase() === item.address.toUpperCase()
              }
              else {
                return false;
              }

            });


            return (<DragAndDropItem
              key={item.id}
              item={item}
              isDraggingOver={isDraggingOver}
              handleDragStart={handleDragStart}
              handleDragOver={handleDragOver}
              handleDragLeave={handleDragLeave}
              handleDrop={handleDrop}
              handleDragEnd={handleDragEnd}
              handleDragEnter={handleDragEnter}
              draggedItemIndex={draggedItemIndex}
              hoveredIndex={hoveredIndex}
              reference={props.reference}
              reference2={props.reference2}
              reference3={ref3filtered}
              loadingReference={props.loadingReference}
  
              showTicker={props.showTicker ? true : false}
              showBalance={props.showBalance ? true : false}
              handleOnClick={handleOnClick}
              selected={props.selected}
            />)
          }
        )}
        <DragAndDropItemDisabled id={-2}/>
      </div>
    </div>
  );
};

export default DragAndDropArea;