import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import LoadingImg from "../../../Art/Animated/loading.gif";
import { nFormatter, getSignificantDigits } from "../HelperFunctions";
import { tokenImgs } from "../HelperFunctions";

const DragAndDropItem = (props) => {
    const [isDragging, setIsDragging] = useState(false);
    const [isOver, setIsOver] = useState(false);
    const [currentChain, setCurrentChain] = useState(false);

    useEffect(() => {
      if(props.showTicker) {
        const toggleInterval = setInterval(() => {
          setCurrentChain((prevChain) => !prevChain);
        }, 10000);
  
        return () => {
          clearInterval(toggleInterval);
        };
      }

      return;
    }, []);

    const handleDragStart = (event, item) => {
        setIsDragging(true);
        props.handleDragStart(event, item);
    };

    const handleDragOver = (event, item) => {
        event.preventDefault();
        setIsOver(true);
        setIsDragging(false);
        props.handleDragOver(event, item);
    };

    const handleDragLeave = event => {
        setIsOver(false);
        props.handleDragLeave(event);
    };

    const handleDrop = (event, item) => {
        setIsOver(true);
        setIsDragging(false);
        props.handleDrop(event, item);
    };


    const handleDragEnter = (event, item) => {
        props.handleDragEnter(event, item);
    }

    const animateItem = (item) => {
      if(item.itemIndex >= 0) {
        // do this for item if it's the one being dragged
        if(props.draggedItemIndex < 0) return "";
        if(props.draggedItemIndex == item.itemIndex) return "";

        
        else if (props.hoveredIndex === item.itemIndex)
          return "animate-right";
      }
    }

    const handleOnClick = (event, item) => {
      props.handleOnClick(event, item);
    }

    const selectItem = (item) => {
      if(props.selected !== null && props.selected !== undefined && props.selected.id === item.id)
        return "selected"
      else
        return "";
    }

    const handleCopyClick = () => {
      navigator.clipboard.writeText(props.item.address)
        .then(() => alert('Address for ' + props.item.name + ' copied to clipboard'))
        .catch(() => alert('Error copying ' +props.item.name + ' to clipboard'));
    }

    const showImage = () => {
      // Show image if props reference provided
      if(props.loadingReference) {
        return(
          <div className="ticker-image">
            <img src={LoadingImg}/>
          </div>
        )
      }
      if(!props.reference) return;

      let logo_url = "";
      let exists = false;
      for(let i = 0; i < props.reference.length; i++){
        if(props.reference[i]?.symbol === props.item.name){
          exists = true;
          logo_url = props.reference[i].logo_url;
          break;
        }
      }

      if(logo_url === null || logo_url === undefined || logo_url === "") {
        if(tokenImgs[props.item.name]) {
          logo_url = tokenImgs[props.item.name]
          exists = true;
        }
      }

      if(!exists) return "";
      if(exists) return (
        <div className="ticker-image">
          <img src={logo_url}/>
        </div>
      )
    }

    const showTicker = () => {
      // Show ticker if props reference provided
      if(!props.reference) return;

      let itemRef = props.item.nomics_id;
      let itemPlat = props.item.platform;
      let myRef = null;
      for(let i = 0; i < props.reference.length; i++) {
        if(props.reference[i].nomics_id === itemRef 
          // && props.reference[i].platform === itemPlat
          ){
          myRef = props.reference[i];
          break;
        }
      }

      if(myRef === null) return ""

      // replace with whatever price is selected
      let selectedCurrency = props.reference2;
      let showInCurrency = "USDC";
      
      // if(props.item.platform === "PLS") debugger;

      if(selectedCurrency !== null) {
        showInCurrency = selectedCurrency.id
      }
      let priceRef = myRef.nomics_id + "_" + showInCurrency;
      
      let price = myRef[priceRef];
      let displayPrice = getSignificantDigits(parseFloat(price));

      if(showInCurrency === "USDC" || showInCurrency === "pDAI"){
        displayPrice = "$ " + displayPrice;
      }
      else if (showInCurrency === itemRef) {
        displayPrice = "1 " + selectedCurrency.symbol;
      }
      else {
        displayPrice += " " + selectedCurrency.symbol;
      }
      /*
      let priceColor = "gray";
      let pricePctChange = parseFloat(ref["1h"].price_change_pct);
      if(pricePctChange > 0.05) priceColor = "green"
      else if (pricePctChange < 0.05)
      */

      return (
        <div className="ticker-price">
          {displayPrice}
        </div>
      )
    }

    const showBalance = () => {
      if(!props.reference || !props.reference3 || props.reference.length < 1) {
        return ""
      }

      let totalValue = 0;
      let selectedCurrency = props.reference2;
      let showInCurrency = selectedCurrency ? selectedCurrency.id : "USDC";

      for(let i = 0; i < props.reference3.length; i++) {
        let itemRef = props.reference3[i].token.nomics_id;
        if(!itemRef){
          return ""
        }

        
        //let quoteRef = showInCurrency === "USDC" ? "price" : itemRef+"_"+showInCurrency;
        let quoteRef = "price";
        
        // if(itemRef === showInCurrency) {
        //   totalValue += parseFloat(props.reference3[i].balance);
        // }
        
        for(let k = 0; k < props.reference.length; k++) {
          if(props.reference[k].id === itemRef) {
            let convertedValue = parseFloat(props.reference3[i].balance) * parseFloat(props.reference[k][quoteRef]);
            totalValue += convertedValue;
            break;
          }
        }
      }


      // replace with whatever price is selected
      
      
      let displayPrice = nFormatter(totalValue, 2)
      displayPrice = "$ " + displayPrice;
      
      // if(showInCurrency === "USDC"){
      //   displayPrice = "$ " + displayPrice;
      // }
      // else {
      //   displayPrice += " " + selectedCurrency.symbol;
      // }

      let balancesToShow = props.reference3.filter(i=> parseFloat(i.balance) > 0 )
      
      return (
        <div className="wallet-balance">
          {displayPrice}
          {props.reference
          .filter(f=>balancesToShow.find((b) => b?.token.nomics_id === f.nomics_id) )
          .map((i, index)=>{
            let custIndex = index > 5 ? "hide" : index;
            return <div key={index} className={`wallet-balance-token custom-offset-${custIndex}`} ><img src={i.logo_url}/></div>
          })}
        </div>
      )
    }


    let showPlatform = () => {
      let eth = tokenImgs["ETH"]
      let pls = tokenImgs["PLS"]

      let img = props.item.platform === "ETH" ? eth : pls;

      return (
        <div className={`platform-img ${props.item.platform === "ETH" ? "ETH" : "PLS"}`}>
          <img src={img}/>
        </div>
      )
    }




    return (
      <div
        className={`drag-and-drop-item ${isDragging ? "dragging" : ""} ${isOver ? "over" : ""} ${animateItem(props.item)} ${selectItem(props.item)}`}
        draggable="true"
        onDragStart={e => handleDragStart(e, props.item)}
        onDragOver={e => handleDragOver(e, props.item)}
        onDragLeave={e => handleDragLeave(e, props.item)}
        onDrop={e => handleDrop(e, props.item)}
        onDragEnter={e => handleDragEnter(e, props.item)}
        onDragEnd={props.handleDragEnd}
        onClick={e => handleOnClick(e, props.item)}
      >
        {props.showTicker ? showPlatform() : ""}
        <div className="d-inline button padding-r5" onClick={handleCopyClick}>
          <FontAwesomeIcon icon={faClipboard}/>
        </div>
        
        {showImage()}
        {props.item.name}
        {props.showTicker ? showTicker() : ""}
        {props.showBalance ? showBalance() : ""}
      </div>
    );
};

export default DragAndDropItem;