import axios from "axios";
import { getSession } from "../Components/Functional/DataStorage/DataManager";
// axios.defaults.withCredentials = true;
// Add a request interceptor
axios.interceptors.request.use(function(config) {
  if(config.url.includes("https://api.livecoinwatch.com") 
    || config.url.includes("https://graph.pulsechain.com/subgraphs/")) {
    return config;
  }
  else {
    const sessionId = getSession();

    let newHeaders = config.headers;
    config.headers["X-Client-Id"] = sessionId;
  
    config.headers = newHeaders;
    return config;
  }

});

/**
 * Will unpack the response body from reponse object
 * @param {*} response
 *
 */
const onGlobalSuccess = response => {
  /// Should not use if you need access to anything other than the data
  return response.data;
};

const onGlobalError = err => {
  return Promise.reject(err);
};

const API_HOST_PREFIX = process.env.REACT_APP_API_HOST_PREFIX;
const REACT_APP_API_LOCALHOST = process.env.REACT_APP_API_LOCALHOST;
const REACT_APP_API_INFURA = process.env.REACT_APP_API_INFURA;
const REACT_APP_API_NOMICS = process.env.REACT_APP_API_NOMICS;
const REACT_APP_API_NOMICS2 = process.env.REACT_APP_API_NOMICS2;

export {
  onGlobalError,
  onGlobalSuccess,
  API_HOST_PREFIX,
  REACT_APP_API_INFURA,
  REACT_APP_API_NOMICS,
  REACT_APP_API_NOMICS2,
  REACT_APP_API_LOCALHOST
};